import { useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Button from "@components/Button";
import * as Input from "@components/Input";

import playSound from "@helper/playSound";
import { printLabel } from "@helper/printLabel";
import { getSessionStorage, setSessionStorage } from "@helper/sessionStorage";

import btSetup from "@services/btprinter";

const FormOperator = (props) => {
  const { data, setData, setSnackbar, printer, setPrinter, onClose } = props;

  const [inputs, setInputs] = useState({ operator: data.operator });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = (event) => {
    let newValues = { ...inputs };
    newValues[event.target.id] = event.target.value;

    setInputs(newValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (inputs.operator) {
      const param = {
        ...data,
        operator: inputs.operator,
      };

      setSnackbar({
        open: true,
        variant: "success",
        message: "Selamat datang " + inputs.operator,
      });

      setSessionStorage("auth", param);
      setData(param);

      if (getSessionStorage("noprint", 0) === 0) {
        !!!printer.uuid &&
          btSetup(
            setPrinter,
            (characteristic) =>
              printLabel(
                "test",
                characteristic,
                setPrinter,
                setSnackbar,
                param
              ),
            (message) =>
              setSnackbar({
                open: true,
                variant: "error",
                message: message,
              })
          );
      }

      onClose();
    } else {
      setError({
        operator: inputs.operator ? false : true,
      });
      setSnackbar({
        open: true,
        variant: "warning",
        message: "Mohon isi nama operator",
      });
      inputRef.current?.focus();
    }

    setLoading(false);
  };

  useEffect(() => {
    !!error.operator && playSound("deny");
  }, [error]);

  return (
    <>
      <Stack gap={2} alignItems="center">
        <ManageAccountsIcon
          sx={{
            color: "#03a798",
            fontSize: "8rem",

            "@media screen and (min-width: 360px)": {
              fontSize: " 5rem",
            },
          }}
        />

        <Typography
          component="h5"
          variant="h5"
          sx={{
            color: "#0d9488",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: { xs: "1.25rem", md: "2rem" },
          }}
        >
          Masukkan Nama Operator
        </Typography>

        <Stack
          alignItems="center"
          component="form"
          gap={2}
          sx={{ width: "100%" }}
          onSubmit={handleSubmit}
        >
          <Input.Text
            inputRef={inputRef}
            label="Operator"
            id="operator"
            value={inputs["operator"]}
            error={error["operator"]}
            onChange={handleChange}
          />

          <Button type="submit" loading={loading}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default FormOperator;
