import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

import BackspaceIcon from "@mui/icons-material/Backspace";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RefreshIcon from "@mui/icons-material/Refresh";

import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const DataTableToolbar = (props) => {
  const {
    onSearch,
    onRefresh,
    onMaximize,
    onReset,
    onDownload,
    tableFilter,
    tableSort,
    sortOrder,
    sortBy,
  } = props;

  const [searchInput, setSearchInput] = useState("");
  const { width } = useViewport();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchInput);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleClearClick = () => {
    setSearchInput("");
    onReset();
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <Toolbar
      sx={{
        background: "#3b968e",
        color: "#ffffff",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.25rem 0.25rem 0 0",
      }}
    >
      <Stack
        gap={2}
        direction={width > breakPoints.sm ? "row" : "column"}
        justifyContent="space-between"
        sx={{ width: "100%", padding: width > breakPoints.sm ? "0" : "1rem 0" }}
      >
        <TableSearchField
          value={searchInput}
          size="small"
          placeholder="Cari"
          id="custom-css-outlined-input"
          onChange={handleSearchInput}
          sx={{ maxWidth: width > breakPoints.sm ? "50%" : "100%" }}
          InputProps={{
            endAdornment: (
              <BackspaceIcon
                color="action"
                onClick={handleClearClick}
                sx={backspaceIconStyle}
              />
            ),
          }}
        />

        <Stack direction="row" justifyContent="flex-end" gap={1}>
          {((!!tableFilter && !!Object.keys(tableFilter).length) ||
            !(
              tableSort?.sortOrder === sortOrder && tableSort?.sortBy === sortBy
            )) && (
            <TableToolbarButton
              active="true"
              variant="outlined"
              onClick={onReset}
            >
              <FilterAltOffIcon />
              {width > breakPoints.md && <>&nbsp;&nbsp;Reset</>}
            </TableToolbarButton>
          )}

          <TableToolbarButton variant="outlined" onClick={onDownload}>
            <DownloadIcon />
          </TableToolbarButton>

          <TableToolbarButton variant="outlined" onClick={onRefresh}>
            <RefreshIcon />
          </TableToolbarButton>

          {width > breakPoints.xs && (
            <TableToolbarButton variant="outlined" onClick={onMaximize}>
              <FullscreenIcon />
            </TableToolbarButton>
          )}
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default DataTableToolbar;

const TableSearchField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    background: "#ccf2f2",
    borderColor: "#367a75",
    borderRadius: "0.25rem",

    "&.Mui-focused, &:hover": {
      background: "#fdfdfd",
    },

    "&.Mui-focused fieldset": {
      borderColor: "#367a75",
    },
  },
});

const TableToolbarButton = styled(Button)((props) => ({
  color: props.active ? "#3b968e" : "#d5efed",
  borderColor: "#d5efed",
  backgroundColor: props.active ? "#ffffff" : "transparent",

  "&.Mui-focused, &:hover": {
    color: props.active ? "#d5efed" : "#3b968e",
    backgroundColor: props.active ? "#ffffff20" : "#ffffff",
    borderColor: "#d5efed",
  },
}));

const backspaceIconStyle = {
  "&:hover": {
    color: "#e14b4b",
  },

  "&:active": {
    color: "#982a2a",
  },
};
