import React, { useState } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import { styled } from "@mui/material/styles";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const StyledInput = styled(OutlinedInput)(({ theme, error }) => ({
  background: "white",
  marginTop: "0.5rem",

  "&:hover": {
    boxShadow: "0px 0px 1px 3px #d0cec980",

    fieldset: {
      border: error
        ? "1px solid #d32f2f !important"
        : "1px solid #b3b3b3 !important",
    },
  },

  "&.Mui-focused fieldset": {
    boxShadow: "0px 0px 1px 3px #d0cec980",
    border: error
      ? "1px solid #d32f2f !important"
      : "1px solid #b3b3b3 !important",
  },

  "& .MuiOutlinedInput-input": {
    height: "2rem",
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    padding: "10px 12px",
    textAlign: "left",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const TextField = (props) => {
  const { inputRef, id, type, label, value, placeholder, onChange, error } =
    props;

  const [inputValue, setInputValue] = useState(value || "");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    onChange({ target: { id: event.target.id, value: event.target.value } });

    setInputValue(event.target.value);
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "0 0.25rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          variant="h6"
          component="h6"
          color="#747474"
          sx={{
            fontSize: "1.15rem",
            "@media screen and (max-width: 768px)": { fontSize: "1rem" },
          }}
        >
          {label || "unlabeled"}
        </Typography>
      </Box>

      <StyledInput
        autoComplete="off"
        type={!showPassword ? type : "text"}
        inputRef={inputRef}
        id={id}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        error={error}
        sx={{
          width: "100%",
        }}
        endAdornment={
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(true)}
                onMouseDown={() => setShowPassword(false)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
      />
    </Stack>
  );
};

export default TextField;
