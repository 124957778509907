import playSound from "@helper/playSound";
import printAllLabel, { printLabel } from "@helper/printLabel";
import { useEffect, useRef, useState } from "react";

import Loader from "@components/Loader";
import { timestamp } from "@helper/dateTimeHelper";
import Stack from "@mui/material/Stack";
import guestAPI from "src/services/api/guestAPI";
import CancellableBox from "./CancellableBox";
import { confirmInputs } from "./Confirm.const";
import ConfirmBox from "./ConfirmBox";
import RetryConnBox from "./RetryConnBox";
import SuccessBox from "./SuccessBox";

import { useMutation, useQueryClient } from "@tanstack/react-query";

const Confirm = ({
  show,
  type,
  data,
  onClose,
  setSnackbar,
  printer,
  setPrinter,
}) => {
  const [inputValues, setInputValues] = useState([]);
  const [guestInfo, setGuestInfo] = useState({});
  const [confirmStep, setConfirmStep] = useState("load");
  const [isRetrying, setIsRetrying] = useState(false);
  const [error, setError] = useState("");
  const [printData, setPrintData] = useState("");
  const queryClient = useQueryClient();
  const ref = useRef({ timestamp: timestamp() });

  const isConfirm = type === "confirm";

  useEffect(() => {
    if (!!show) {
      if (isConfirm) {
        fetchInfo();
      } else {
        setConfirmStep("conf");
        setGuestInfo({ ...data });
      }
    }
  }, [show]);

  const fetchInfo = async () => {
    setIsRetrying(true);

    try {
      const info = !!data.ivts_Name
        ? { ...data }
        : await guestAPI.info(data.clientId.toString(), data.ivts_Uuid);

      setGuestInfo(info);

      /* Input values initialization */
      if (!!!info.ivts_GuestAttTime) {
        let initValues = {};
        confirmInputs.map((input) => {
          initValues[input.id] = info[input.rsvpValue] || 0;
        });

        setInputValues(initValues);
      }

      if (!!info.ivts_GuestAttTime) {
        setConfirmStep("prep");
      } else {
        setConfirmStep("conf");
      }

      setTimeout(() => {
        playSound("welcome");
      }, 500);
    } catch {
      setSnackbar({
        open: true,
        variant: "error",
        message: "Jaringan bermasalah, mohon cek koneksi anda...",
      });

      setConfirmStep("retry");
    }

    setIsRetrying(false);
  };

  const handleChange = (event) => {
    let newValues = { ...inputValues };
    newValues[event.target.id] = event.target.value;

    setInputValues(newValues);
  };

  const { mutate: submitData, isLoading = false } = useMutation({
    mutationFn: async () => {
      return await guestAPI[type](
        data?.clientId.toString(),
        { ...inputValues },
        data?.operator,
        ref.current.timestamp,
        guestInfo.ivts_Uuid || ""
      );
    },
    onSuccess: (prnParam) => {
      playSound("confirm");

      setSnackbar({
        open: true,
        variant: "success",
        message: "Konfirmasi berhasil !",
      });

      prnParam = { ...prnParam, ...guestInfo };

      setPrintData(prnParam);
      printAllLabel(printer, setPrinter, setSnackbar, prnParam);

      setConfirmStep("done");

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["guest"] });
    },
    onError: (err) => {
      playSound("deny");

      setSnackbar({
        open: true,
        variant: "error",
        message:
          err === "ERR_GUESTNAME"
            ? "Nama tamu belum diisi"
            : "Konfirmasi gagal, mohon cek koneksi anda...",
      });

      err === "ERR_GUESTNAME" &&
        setError({
          guest_name: true,
        });
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault(event);
    submitData();
  };

  const handlePrint = (type) => {
    printLabel(type, printer, setPrinter, setSnackbar, printData);
  };

  return (
    <>
      {confirmStep === "load" ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "15rem" }}
        >
          <Loader size="4rem" color="#9b9178" />
        </Stack>
      ) : confirmStep === "prep" ? (
        <CancellableBox
          data={guestInfo}
          setConfirmStep={setConfirmStep}
          onClose={onClose}
        />
      ) : confirmStep === "conf" ? (
        <ConfirmBox
          type={type}
          data={guestInfo}
          inputValues={inputValues}
          error={error}
          guestInfo={guestInfo}
          isLoading={!!isLoading}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      ) : confirmStep === "retry" ? (
        <RetryConnBox
          isLoading={!!isRetrying}
          fetchInfo={fetchInfo}
          onClose={onClose}
        />
      ) : (
        <SuccessBox
          guestName={guestInfo?.ivts_Name}
          print={handlePrint}
          printData={printData}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Confirm;
