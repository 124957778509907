import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const NavbarMenuItem = (props) => {
  const { text, icon, onClick } = props;

  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="center"
      sx={{
        padding: "1rem 2rem",
        minWidth: "10rem",

        "&:hover, &:active": {
          background: "#ececec",
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography component="span" variant="body1" sx={{ marginLeft: "1rem" }}>
        {text}
      </Typography>
    </Stack>
  );
};

export default NavbarMenuItem;
