/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectField = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      background: "red",
      borderColor: "#367a75",
    },
  },
});

const DataTableMenu = ({ items, multiple, itemsSelected, sx }) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setOptions(items.map((item) => item.value));

    if (multiple) {
      const defaultItems = items.filter((item) => item.default === true);
      setSelected(defaultItems.map((item) => item.value));
    }
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;

    if (value.includes("default")) {
      value.pop();
      value = items
        .filter((item) => item.default === true)
        .map((item) => item.value);
    } else if (value.includes("all")) {
      value.pop();
      value = items.map((item) => item.value);
    }

    setSelected(value);
    itemsSelected(value);
  };

  return (
    <FormControl sx={{ ...sx, m: 2 }}>
      <SelectField
        multiple={multiple || false}
        displayEmpty
        size="small"
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={() => {
          if (multiple) {
            return <em>Opsi Kolom</em>;
          } else {
            return selected;
          }
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Opsi Kolom</em>
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {selected.indexOf(option) !== -1 ? (
              <CheckBoxOutlinedIcon fontSize="1.5rem" color="#3c9b29" />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon
                fontSize="1.5rem"
                color="#404040"
              />
            )}
            <span style={{ marginLeft: "0.5rem" }}>{option}</span>
          </MenuItem>
        ))}

        <MenuItem key="selectall" value="all">
          Select All
        </MenuItem>

        <MenuItem key="default" value="default">
          Reset to default
        </MenuItem>
      </SelectField>
    </FormControl>
  );
};

export default DataTableMenu;
