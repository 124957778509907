import { itemCount } from "@helper/giftHelper";
import apiRequest from "../apiRequest";

const ENDPOINT = "guestRegister";

const register = async (client_id, param, operator, timestamp) => {
  if (!!!param.guest_name) {
    throw "ERR_GUESTNAME";
  }

  let registerParam = {
    guest_name: param.guest_name,
    guest_addr: param.guest_addr || "",
    guest_count: param.guest_count || 0,
    souvenir_count: param.souvenir_count || 0,
    angpau_count: param.angpau_count || 0,
    gift: param.gift || "",
    gift_count: itemCount(param.gift),
    client_id: client_id,
    operator: operator,
    timestamp: timestamp,
  };

  const response = await apiRequest("post", ENDPOINT, registerParam, 10000);

  if (response.status !== true && !response.label.includes("registered")) {
    throw "CONN";
  }

  return registerParam;
};

export default register;
