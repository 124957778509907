/* eslint-disable default-case */
import { useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Button from "@components/Button";
import * as Input from "@components/Input";

import playSound from "@helper/playSound";
import { setSessionStorage } from "@helper/sessionStorage";

import authAPI from "@services/api/authAPI";

const FormLogin = (props) => {
  const { setData, setSnackbar } = props;

  const [inputs, setInputs] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    !!error && playSound("deny");
  }, [error]);

  const handleChange = (event) => {
    let newValues = { ...inputs };
    newValues[event.target.id] = event.target.value;

    setInputs(newValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    await authAPI
      .auth(inputs.username, inputs.password)
      .then((reply) => {
        const param = {
          clientId: reply.client_Id,
          username: inputs.username,
        };

        setSessionStorage("auth", param);
        setData(param);

        setSnackbar({
          open: true,
          variant: "success",
          message: "Login Berhasil !",
        });
      })
      .catch((error) => {
        switch (error) {
          case "USER":
            setError({
              username: true,
              password: false,
            });
            setSnackbar({
              open: true,
              variant: "error",
              message: "Username tidak terdaftar",
            });
            break;
          case "PASS":
            setError({
              username: false,
              password: true,
            });
            setSnackbar({
              open: true,
              variant: "error",
              message: "Password salah",
            });
            break;
          case "CONN":
            setSnackbar({
              open: true,
              variant: "error",
              message: "Koneksi bermasalah",
            });
            break;
          case "INPUT":
            setError({
              username: inputs.username ? false : true,
              password: inputs.password ? false : true,
            });
            setSnackbar({
              open: true,
              variant: "warning",
              message: "Input tidak lengkap",
            });
            break;
        }
      });

    setLoading(false);
  };

  return (
    <>
      <Stack gap={2} alignItems="center">
        <Typography
          component="h4"
          variant="h4"
          sx={{ color: "#0d9488", textAlign: "center", fontWeight: "bold" }}
        >
          Login
        </Typography>

        <Stack
          gap={3}
          component="form"
          onSubmit={handleSubmit}
          sx={{ height: "inherit", width: " 100%", margin: "0.5rem auto" }}
        >
          <Input.Text
            inputRef={inputRef}
            label="Username"
            id="username"
            value={inputs["username"]}
            onChange={handleChange}
            error={error["username"]}
          />

          <Input.Text
            type="password"
            label="Password"
            id="password"
            value={inputs["password"]}
            onChange={handleChange}
            error={error["password"]}
          />

          <Button size="large" type="submit" loading={loading}>
            Login
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default FormLogin;
