import { useState, useEffect } from "react";
import { NavLink as Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";

import NavbarItem from "./NavbarItem";
import NavbarMenu from "./NavbarMenu";

import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const NavbarContainer = (props) => {
  const { logo, items, onItemClick } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const { width } = useViewport();

  useEffect(() => {
    setScrollY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => setShowMenu(false));

    return () => {
      window.removeEventListener("scroll", () => setShowMenu(false));
    };
  }, [scrollY]);

  const handleItemClick = (dispatch) => {
    if (width <= breakPoints.md) {
      /* Let the hover styling shows first (for mobile) */
      setTimeout(() => onItemClick(dispatch), 50);
    } else {
      onItemClick(dispatch);
    }
  };

  return (
    <Box
      sx={{
        zIndex: "1",
        height: "5rem",
        padding: "0.2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#4d4d4d",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          zIndex: "2",
          height: "5rem",
          width: "60rem",
          margin: "0 1rem",
          display: "flex",

          "@media screen and (max-width: 768px) ": {
            width: "42rem",
          },
        }}
      >
        <Link to="/">{logo}</Link>

        <Stack direction="row">
          {items
            .filter((item) => item.type !== "menu")
            .map(
              (item, index) =>
                (width > breakPoints.md || item.type === "action") && (
                  <NavbarItem
                    key={"NavbarItem_" + index}
                    onClick={() => handleItemClick(item.dispatch || null)}
                  >
                    {item.type === "action" ? (
                      item.icon
                    ) : (
                      <Link to={String(item.link)}>{item.text}</Link>
                    )}
                  </NavbarItem>
                )
            )}

          <NavbarItem
            onClick={() => setShowMenu(!showMenu)}
            onMouseLeave={() => setShowMenu(false)}
            sx={{ position: "relative" }}
          >
            <MenuIcon className={showMenu ? "active" : ""} />

            {showMenu && <NavbarMenu {...props} />}
          </NavbarItem>
        </Stack>
      </Stack>
    </Box>
  );
};

export default NavbarContainer;
