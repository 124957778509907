const getCamera = async () => {
  let devicelist = [];
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
  } else {
    // List cameras and microphones.
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(function (device) {
          if (device.kind === "videoinput") devicelist.push(device);
        });
      })
      .catch(function (err) {
        console.log(`${err.name}: ${err.message}`);
      });
  }

  return devicelist;
};

export default getCamera;
