import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";

import Button from "@components/Button";

const InfoBlock = (props) => {
  const { title, text } = props;

  return (
    <Stack justifyContent="flex-start">
      <Typography
        variant="h4"
        component="span"
        sx={{
          fontSize: "0.75rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: "#0d9488",
          fontSize: "1.25rem",
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const NavbarUserInfo = (props) => {
  const { userInfo, onItemClick } = props;

  const handleClick = () => {
    onItemClick("operator");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        gap={1}
        sx={{
          backgroundColor: "#f3f3f3",
          padding: " 1rem 1.5rem",
          margin: "0.5rem",
          borderRadius: "0.5rem",
        }}
      >
        <InfoBlock title="Username" text={userInfo.username} />
        <Divider />
        <InfoBlock title="Operator" text={userInfo.operator} />

        <Button
          variant="outlined"
          sx={{ padding: "0", marginTop: "0" }}
          onClick={handleClick}
        >
          <Stack gap="0.25rem" direction="row" alignItems="center">
            <EditIcon fontSize="0.3rem" />
            <Typography variant="body" component="span">
              Edit
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Box>
  );
};

export default NavbarUserInfo;
