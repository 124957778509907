import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import SearchbarInput from "./SearchbarInput";
import SearchbarResult from "./SearchbarResult";

import { sortItems } from "@helper/itemsHelper";
import { Stack } from "@mui/material";

const sortResult = (result) => {
  const sortedResult = sortItems(result, "desc", "ivts_GuestAttTime");

  const confirmed = sortItems(
    sortedResult.filter((item) => !!item.ivts_GuestAttTime),
    "desc",
    "ivts_GuestAttStatus"
  );

  const unconfirmed = sortItems(
    sortedResult.filter((item) => !!!item.ivts_GuestAttTime),
    "asc",
    "ivts_Name"
  );

  return [...confirmed, ...unconfirmed];
};

const SearchBar = ({ guestdata, setData, onClose }) => {
  const [searchData, setSearchData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQueries, setSearchQueries] = useState({});
  const inputRef = useRef(null);

  const isLoading = guestdata.length === 0;

  useEffect(() => {
    if (!!!isLoading && searchData.length === 0) {
      const data = sortResult(guestdata);
      setSearchData(data);
    }
  }, [guestdata]);

  const handleSearch = async () => {
    let data = [...searchData];

    if (!!searchQueries) {
      for (const [key, value] of Object.entries(searchQueries)) {
        if (key !== "ivts_Anggota") {
          data = data.filter((item) => {
            if (item[key] !== null) {
              if (key === "ivts_Name") {
                return (
                  item.ivts_Name
                    ?.toLowerCase()
                    .includes(searchQueries.ivts_Name.toLowerCase()) ||
                  item.ivts_Anggota
                    ?.toLowerCase()
                    .includes(searchQueries.ivts_Anggota?.toLowerCase())
                );
              } else {
                return item[key].toLowerCase().includes(value.toLowerCase());
              }
            }
          });
        }
      }
    }

    setSearchResults(data);
  };

  useEffect(() => {
    handleSearch();
  }, [searchData, searchQueries]);

  const handleClose = () => {
    onClose();
  };

  const handleScroll = () => {
    inputRef.current.blur();
  };

  return (
    <Modal open={true}>
      <Box
        id="searchbox"
        onClick={(e) => e.target.id === "searchbox" && handleClose()}
        sx={{ height: "100%" }}
      >
        <Stack
          sx={{
            maxWidth: "60rem",
            maxHeight: "100%",
            margin: "0 auto",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "flex-start",

            "@media screen and (max-width: 768px)": {
              maxWidth: "95vw",
            },
          }}
        >
          <SearchbarInput
            data={searchData}
            inputRef={inputRef}
            isLoading={isLoading}
            results={searchResults}
            searchQueries={searchQueries}
            setSearchQueries={setSearchQueries}
            onSubmit={handleSearch}
            onClose={handleClose}
          />

          <SearchbarResult
            isLoading={isLoading}
            queries={searchQueries}
            results={searchResults}
            maxResult={15}
            onScroll={handleScroll}
            setData={setData}
            onClose={handleClose}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default SearchBar;
