import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

import getCamera from "@services/getCamera";
import { getSessionStorage, setSessionStorage } from "@helper/sessionStorage";

import { QrReader } from "react-qr-reader";
import useViewport from "@hooks/useViewport";

const QrScanner = ({ isLoading = false, onScanned }) => {
  const [cameraOff, setCameraOff] = useState(false);
  const [camera, setCamera] = useState(
    getSessionStorage("cam", { devices: [], facing: "user" })
  );

  const { height, width } = useViewport();

  const scandelay = 100;

  const handleScan = (qrcode, error) => {
    if (!!qrcode) {
      onScanned(qrcode);
    }

    if (!!error) {
      //console.log(error);
    }
  };

  const handleCameraOff = () => {
    setCameraOff(!cameraOff);
  };

  const handleCameraSwitch = () => {
    /* Force video component to re-render */
    setCamera({ ...camera, facing: null });
    if (camera.facing === "environment") {
      setTimeout(() => {
        setCamera({ ...camera, facing: "user" });
        setSessionStorage("cam", camera);
      }, 10);
    } else {
      setTimeout(() => {
        setCamera({ ...camera, facing: "environment" });
        setSessionStorage("cam", camera);
      }, 10);
    }
  };

  useEffect(() => {
    const getCam = async () => {
      const list = await getCamera();
      return list;
    };

    getCam().then((list) => setCamera({ ...camera, devices: list }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack direction="row" sx={{ position: "relative" }}>
        <QrScannerButton
          onClick={handleCameraOff}
          sx={{ position: "absolute", top: "1rem", left: "1rem" }}
        >
          {cameraOff ? <CameraAltIcon /> : <NoPhotographyIcon />}
        </QrScannerButton>

        {camera.devices.length >= 2 ? (
          <QrScannerButton
            onClick={handleCameraSwitch}
            sx={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <CameraswitchIcon />
          </QrScannerButton>
        ) : (
          <></>
        )}
      </Stack>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "100%",
          borderRadius: "0.5rem",
          overflow: "hidden",

          "& > section": {
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",

            "& > div": {
              width: "100%",
              height: "40%",

              "> video": {
                top: "50%",
                left: "50%",
                objectFit: "cover",
              },
            },
          },
        }}
      >
        {!isLoading ? (
          !!camera.facing && !cameraOff ? (
            <QrReader
              isPortrait={height > width ? true : false}
              scanDelay={scandelay}
              onResult={(qrdata, err) => {
                handleScan(qrdata?.text, err);
              }}
              constraints={{ facingMode: camera.facing }}
            />
          ) : (
            <NoPhotographyIcon sx={{ fontSize: "5rem", color: "#9c9c9c80" }} />
          )
        ) : (
          <CircularProgress
            sx={{
              color: "#5f9ea0",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default QrScanner;

const QrScannerButton = (props) => {
  const { children, onClick, sx } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        background: "#00000060",
        border: "none",
        zIndex: "1",
        padding: "1rem",
        borderRadius: "0.25rem",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

        "&:hover, &:active": {
          background: "#00000080",
        },

        "& > *": { fill: "#ffffff" },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
