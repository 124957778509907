import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

import { EnvelopeOffIcon } from "../../assets/images/Icons";

const StyledTableRow = styled(TableRow)`
  &:last-child {
    & .MuiTableCell-root {
      border-bottom: none;
    }
  }
`;

const getIcon = (name) => {
  let icon = <></>;

  switch (name) {
    case "person":
      icon = <GroupOutlinedIcon />;
      break;
    case "personoff":
      icon = <PersonOffOutlinedIcon />;
      break;
    case "present":
      icon = <CheckBoxOutlinedIcon />;
      break;
    case "list":
      icon = <ListAltOutlinedIcon />;
      break;
    case "envelope":
      icon = <MailOutlinedIcon />;
      break;
    case "envelopeoff":
      icon = <EnvelopeOffIcon />;
      break;
    case "souvenir":
      icon = <LocalActivityOutlinedIcon />;
      break;
    case "angpau":
      icon = <PaidOutlinedIcon />;
      break;
    case "gift":
      icon = <LocalMallOutlinedIcon />;
      break;
    default:
      icon = <></>;
      break;
  }

  icon = (
    <Typography
      component="span"
      color="#505050"
      fontSize="1.5rem"
      sx={{
        //display: "inline-flex",
        //alignItems: "center",
        "& .MuiSvgIcon-root": {
          fontSize: { xs: "1rem", sm: "1.25rem" },
          margin: "auto",
        },
      }}
    >
      {icon}
    </Typography>
  );

  return icon;
};

const Card = (props) => {
  const { summary } = props;

  return (
    <Stack
      justifyContent="center"
      sx={{
        background: "#ffffff",
        borderRadius: "0.25rem",
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="body2"
        component="span"
        sx={{
          width: "100%",
          margin: "0 auto 0.5rem",
          padding: "0.5rem 0",
          textAlign: "center",
          background: summary.background || "#3b968e",
          color: "#ffffff",
          fontSize: { xs: "0.75rem", sm: "0.85rem" },
        }}
      >
        {summary.title}
      </Typography>

      <Box sx={{ margin: 1, height: "100%" }}>
        <Table size="small" aria-label="guestsummary">
          <TableBody>
            {summary.data.map((row, index) => (
              <StyledTableRow
                key={"monitorcard_" + summary.title + "_" + index}
              >
                <TableCell
                  align="left"
                  sx={{
                    height: { xs: "1rem", sm: "2rem", md: "3rem" },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: { xs: "0.75rem", sm: "0.85rem" },
                    }}
                  >
                    {row.label}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack
                    gap={1}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "0.75rem",
                          sm: "0.85rem",
                        },
                      }}
                    >
                      {row.value}
                    </Typography>
                    {getIcon(row.icon)}
                  </Stack>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  );
};

export default Card;
