import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import NavbarMenuItem from "./NavbarMenuItem";

import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";
import NavbarUserInfo from "./NavbarMenuInfo";

const NavbarMenu = (props) => {
  const { items, onItemClick } = props;

  const { width } = useViewport();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        top: "3rem",
        right: "0",
        transition: "all 0.2s ease-in-out",
      }}
    >
      <Stack
        direction="column"
        justifyContent="start"
        alignItems="center"
        sx={{
          marginTop: "0.5rem",
          width: "max-content",
          border: "1px solid transparent",
          borderRadius: "0.2rem",
          background: "#ffffff",
          boxShadow: "0px 2px 10px #888888",
          overflow: "hidden",
        }}
      >
        <NavbarUserInfo {...props} />

        <Divider sx={{ width: "80%", margin: "0.5rem 0" }} />

        {items
          .filter(
            (item) =>
              item.type === "menu" ||
              (item.type !== "action" && width <= breakPoints.md)
          )
          .map((item, index) => (
            <NavbarMenuItem
              key={"NavbarMenuItem_" + index}
              text={item.text}
              icon={item.icon}
              onClick={() =>
                !!item.link ? navigate(item.link) : onItemClick(item.dispatch)
              }
            />
          ))}
      </Stack>
    </Box>
  );
};

export default NavbarMenu;
