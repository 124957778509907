import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import BluetoothIcon from "@mui/icons-material/Bluetooth";

import Button from "@components/Button";

import { clearSessionStorage } from "@helper/sessionStorage";

const FormEnablePrint = (props) => {
  const { onConnect, onClose } = props;

  const handleConnect = async () => {
    clearSessionStorage("noprint");
    onConnect();
    onClose();
  };

  return (
    <>
      <Stack gap={2} alignItems="center">
        <BluetoothIcon
          sx={{
            color: "#03a798",
            fontSize: "8rem",

            "@media screen and (min-width: 360px)": {
              fontSize: " 5rem",
            },
          }}
        />

        <Typography
          component="h5"
          variant="h5"
          sx={{
            color: "#0d9488",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: { xs: "1rem", md: "1.25rem" },
          }}
        >
          Aktifkan Printer ?
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          component="form"
          gap={2}
          sx={{ width: "100%" }}
        >
          <Button onClick={handleConnect}>Yes</Button>
          <Button variant="outlined" onClick={onClose}>
            No
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default FormEnablePrint;
