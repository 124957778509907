/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import Portal from "@mui/material/Portal";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { findUnique } from "@helper/itemsHelper";
import rem2px from "@helper/rem2px";
import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const DataTableHeader = ({
  headers,
  data,
  tableFilter,
  setTableFilter,
  tableSort,
  onTableSort,
  portalRef,
}) => {
  const [showOption, setShowOption] = useState(null);
  const [options, setOptions] = useState(null);
  const { width } = useViewport();
  const optionRef = useRef(null);

  const getOptions = (column) => {
    const options = headers.find((item) => item.value === column).options;
    return options || findUnique(data, column);
  };

  useEffect(() => {
    if (!!showOption) {
      setOptions(getOptions(showOption.value));
    } else {
      setOptions(null);
    }
  }, [showOption]);

  useEffect(() => {
    if (!!showOption && options.length > 1) {
      setTimeout(() => {
        optionRef.current?.focus();
        optionRef.current?.select();
      }, 100);
    }
  }, [options]);

  const handleChange = (id, value) => {
    if (!!id) {
      let newValues = { ...tableFilter };
      const valueId = id;

      if (value.length === 0) {
        delete newValues[valueId];
      } else {
        newValues[valueId] = value;
      }

      setTableFilter(newValues);
    }
  };

  const handleMenuOpen = (param) => {
    setShowOption({ ...param });
  };

  const handleMenuClose = () => {
    setShowOption(null);
  };

  return (
    <>
      <TableHead>
        <TableRow
          key="datatable_head_row"
          sx={{
            position: "sticky",
            zIndex: "1",
            top: "0",
            width: "100%",
            height: "2rem",
            background: "#d5efed",
            color: "#3b968e",
          }}
        >
          <TableCell
            key="datatable_cell_icon"
            sx={{
              height: "auto !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#3b968e",
              borderRight: "1px solid #b7dfdc",
            }}
          >
            <AccessTimeOutlinedIcon />
          </TableCell>
          {headers.map((column, index) => (
            <TableCell
              key={"datatable_cell_column" + index}
              align={column.align}
              sx={{
                position: "relative",
                minWidth:
                  column.type === "checkbox" && width < breakPoints.sm
                    ? "3.5rem"
                    : column.width,
                height: "auto !important",
                fontWeight: "bold",
                display: column.hide ? "none" : "",
                color: "inherit",
                borderRight: "1px solid #b7dfdc",
                background: headerCompare(showOption, column)
                  ? "#ffffff"
                  : "transparent",

                "&:hover": {
                  background: headerCompare(showOption, column)
                    ? "#ffffff"
                    : "#0000000A",
                },

                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "3rem",
                  left: "0",
                  height: "1rem",
                  width: "100%",
                  background: headerCompare(showOption, column)
                    ? "#ffffff"
                    : "transparent",
                },
              }}
              onClick={() => {
                if (column.filterable) {
                  if (!headerCompare(showOption, column)) {
                    handleMenuOpen(column);
                  }
                } else {
                  onTableSort(column.value);
                }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  {column.type === "checkbox" && width < breakPoints.sm
                    ? ""
                    : column.header}{" "}
                  {getSortIcon(column.value, tableSort)}
                </span>

                {!!tableFilter && !!tableFilter[column.value] ? (
                  <FilterAltIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </Stack>

              {width > breakPoints.sm &&
                column.filterable &&
                headerCompare(showOption, column) &&
                !!options && (
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <Paper
                      elevation={3}
                      sx={{
                        position: "absolute",
                        top: "3.5rem",
                        left: "0",
                        background: "white",
                        padding: "0.25rem",
                        fontSize: width < breakPoints.md ? "0.8rem" : "1rem",
                        width:
                          rem2px(column.width) < rem2px("15rem")
                            ? "15rem"
                            : "100%",
                      }}
                    >
                      <HeaderMenu
                        inputRef={optionRef}
                        column={column}
                        options={options}
                        tableFilter={tableFilter}
                        tableSort={tableSort}
                        onSort={onTableSort}
                        onChange={handleChange}
                        onClose={handleMenuClose}
                      />
                    </Paper>
                  </ClickAwayListener>
                )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      {width <= breakPoints.sm && (
        <Portal container={portalRef?.current}>
          <SwipeableDrawer
            anchor="bottom"
            open={!!showOption}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
          >
            {!!showOption && (
              <HeaderMenu
                inputRef={optionRef}
                column={showOption}
                options={options}
                tableFilter={tableFilter}
                tableSort={tableSort}
                onSort={onTableSort}
                onChange={handleChange}
                onClose={handleMenuClose}
              />
            )}
          </SwipeableDrawer>
        </Portal>
      )}
    </>
  );
};

export default DataTableHeader;

const getSortIcon = (column, sort) => {
  let icon = <></>;

  if (column === sort.sortBy) {
    if (sort.sortOrder === "asc") {
      icon = <ArrowUpwardIcon fontSize="1rem" sx={{ marginLeft: "0.25rem" }} />;
    } else if (sort.sortOrder === "desc") {
      icon = (
        <ArrowDownwardIcon fontSize="1rem" sx={{ marginLeft: "0.25rem" }} />
      );
    }
  }

  return icon;
};

const HeaderMenu = ({
  inputRef,
  column,
  options,
  tableFilter,
  onChange,
  onSort,
  onClose,
}) => {
  return (
    <Stack
      direction="column"
      alignItems="flex-end"
      justifyContent="center"
      spacing={1}
      sx={{ padding: "1rem 0.5rem 0.5rem" }}
    >
      <Stack sx={{ width: "100%", marginBottom: "0.5rem" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <Typography>Filter</Typography>
          <TableMenuButton
            btncolor="#963b3b"
            onClick={() => onChange(column.value, [])}
            sx={{ marginTop: "1rem", width: "max-content", border: "none" }}
          >
            Clear
          </TableMenuButton>
        </Stack>

        <Autocomplete
          multiple
          limitTags={4}
          id={"filter_" + column.value}
          key={"filter_" + column.value}
          options={options}
          value={tableFilter[column.value] || []}
          onChange={(event, newInputValue) =>
            onChange(column.value, newInputValue)
          }
          onKeyDown={(e) =>
            e.key === "Enter" &&
            onChange(
              column.value,
              options.filter((option) =>
                option
                  .toLowerCase()
                  .includes(inputRef.current.value.toLowerCase())
              )
            )
          }
          sx={{ width: "100%", margin: "0.25rem 0" }}
          ListboxProps={{
            style: { maxHeight: "15rem" },
            position: "bottom-start",
          }}
          renderInput={(params) => (
            <TableFilterField
              {...params}
              inputRef={inputRef}
              placeholder={column.header}
              variant="filled"
              sx={{
                maxHeight: "10rem",
                "& .MuiFilledInput-root": {
                  overflow: "auto",
                },
              }}
            />
          )}
        />
      </Stack>

      <TableMenuButton
        btncolor="#3b968e"
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          onSort(column.value, "asc");
        }}
        startIcon={
          <ArrowUpwardIcon fontSize="1rem" sx={{ marginLeft: "0.25rem" }} />
        }
      >
        Sort Ascending
      </TableMenuButton>

      <TableMenuButton
        btncolor="#3b968e"
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          onSort(column.value, "desc");
        }}
        startIcon={
          <ArrowDownwardIcon fontSize="1rem" sx={{ marginLeft: "0.25rem" }} />
        }
      >
        Sort Descending
      </TableMenuButton>

      <TableMenuButton
        btncolor="#963b3b"
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          onClose();
        }}
      >
        Close
      </TableMenuButton>
    </Stack>
  );
};

const headerCompare = (obj1, obj2) => {
  if (!!obj1 && !!obj2 && obj1.header === obj2.header) {
    return true;
  } else {
    return false;
  }
};

const TableMenuButton = styled(Button)((props) => ({
  color: props?.btncolor || "#3b968e",
  border: `1px solid ${props?.btncolor || "#3b968e"}`,
  textTransform: "none",

  "&:hover": {
    backgroundColor: !!props?.btncolor ? props?.btncolor + "20" : "#d5efed",
  },
}));

const TableFilterField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& label": {
    color: "#c5c1b8",
  },
  "& label.Mui-focused": {
    color: "#aba79e",
  },
  "& .MuiFilledInput-root": {
    color: "#464646",
    fontSize: "inherit",
    border: "1px solid #e4e4e4",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "transparent",
    paddingTop: "0",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #6d6d6d",

      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
    },
    "&.Mui-focused": {
      boxShadow: "none",
      backgroundColor: "transparent",
      border: "1px solid #6d6d6d",

      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
    },

    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  "& .MuiAutocomplete-root fieldset:hover": {
    border: "none",
    boxShadow: "none",
  },
  "& .MuiFilledInput-root.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
}));
