import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import Box from "@mui/material/Box";

import Monitoring from "@pages/Monitoring";
import Scan from "@pages/Scan";

import Form from "./features/Form";
import Navbar from "./features/Navbar";
import Searchbar from "./features/Searchbar";

import Snackbar from "@components/Snackbar";

import { printLabel } from "@helper/printLabel";
import { clearSessionStorage, getSessionStorage } from "@helper/sessionStorage";

import btSetup from "@services/btprinter";
import { useQueryClient } from "@tanstack/react-query";
import useFetchTable from "./hooks/useFetchTable";
import Redirect from "./pages/Redirect";

const App = () => {
  const [formData, setFormData] = useState(null);
  const [auth, setAuth] = useState(getSessionStorage("auth", null));
  const [modal, setModal] = useState({ show: false });
  const [search, setSearch] = useState(false);
  const [snackbar, setSnackbar] = useState({});
  const [printer, setPrinter] = useState({});
  const queryClient = useQueryClient();

  const {
    data: guestdata,
    refetch,
    isFetching,
  } = useFetchTable({
    auth,
    enableIteration: false,
    refetchInterval: 30000,
    onError: () =>
      setSnackbar({
        open: true,
        variant: "error",
        message: "Gagal memuat data, mohon cek koneksi anda...",
        duration: 3000,
      }),
  });

  useEffect(() => {
    if (!!!auth) {
      modalOpen("login");
    } else {
      if (!!!auth.operator) {
        modalOpen("operator");
      }
    }
  }, [auth]);

  useEffect(() => {
    if (formData === "n/a") {
      modalOpen("register");
    } else if (!!formData) {
      modalOpen("confirm");
    }

    search && handleSearchClose();
  }, [formData]);

  const modalOpen = (type) => {
    let param = {
      show: true,
      type: type,
      setSnackbar: setSnackbar,
      onClose: handleModalClose,
    };

    switch (type) {
      case "login":
      case "operator":
        param = {
          ...param,
          data: auth,
          setData: setAuth,
        };
        break;
      case "confirm":
        param = {
          ...param,
          data: { ...formData, ...auth },
        };
        break;
      case "register":
        param = {
          ...param,
          data: { ...auth },
        };
        break;
      case "printer":
        param = {
          ...param,
          onConnect: handlePrinterConnect,
        };
        break;
      default:
        break;
    }

    setModal(param);
  };

  const handleNavMenu = (menu) => {
    switch (menu) {
      case "search":
        setSearch(true);
        break;
      case "operator":
        modalOpen("operator");
        break;
      case "printer":
        handlePrinterSetup();
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    queryClient.removeQueries();

    clearSessionStorage("auth");
    setAuth(null);
  };

  const handleModalClose = () => {
    setModal({ ...modal, show: false });

    if (modal.name !== "search") setFormData(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePrinterSetup = () => {
    if (getSessionStorage("noprint", 0) === 0) {
      handlePrinterConnect();
    } else {
      modalOpen("printer");
    }
  };

  const handlePrinterConnect = () => {
    btSetup(
      setPrinter,
      (characteristic) =>
        printLabel("test", characteristic, setPrinter, setSnackbar, auth),
      (message) =>
        setSnackbar({
          open: true,
          variant: "error",
          message: message,
        })
    );
  };

  const handleSearchClose = () => {
    setSearch(false);
  };

  return (
    <Router>
      {auth ? (
        <Box sx={{ maxWidth: "60rem", margin: "0 auto" }}>
          <Navbar userInfo={auth} onItemClick={handleNavMenu} />

          <Box
            sx={{
              margin: "0 1rem 1rem",
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              background: "#f9e9c1",
              borderRadius: "0.5rem",
              "@media screen and max-width:768px": {
                maxWidth: "90vw",
              },
            }}
          >
            <Routes>
              <Route exact path="/" element={<Navigate to="/scan" />} />

              <Route
                path="/scan"
                element={
                  <Scan
                    auth={auth}
                    enabled={!!!modal?.show}
                    guestdata={guestdata}
                    setFormData={setFormData}
                    setSnackbar={setSnackbar}
                  />
                }
              />

              <Route
                path="/noprint"
                element={
                  <Scan
                    auth={auth}
                    enabled={!!!modal?.show}
                    guestdata={guestdata}
                    setFormData={setFormData}
                    setSnackbar={setSnackbar}
                    noPrint={true}
                  />
                }
              />

              <Route
                path="/monitoring"
                element={
                  <Monitoring
                    auth={auth}
                    guestdata={guestdata}
                    onRefresh={refetch}
                    isFetching={isFetching}
                  />
                }
              />

              <Route path="/*" element={<Redirect />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {!!search && (
        <Searchbar
          auth={auth}
          guestdata={guestdata}
          setData={setFormData}
          onClose={handleSearchClose}
        />
      )}

      <Form {...modal} printer={printer} setPrinter={setPrinter} />

      <Snackbar
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        duration={snackbar.duration || 1500}
        onClose={handleSnackbarClose}
      />
    </Router>
  );
};

export default App;
