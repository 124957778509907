import Box from "@mui/material/Box";

const NavbarItem = (props) => {
  const { children } = props;

  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        margin: "0 0.2rem",

        "& > :first-child": {
          color: "#ffffff",
          padding: "0.75rem 1.5rem",
          borderRadius: "0.2rem",
          cursor: "pointer",
          fontWeight: "normal",
          textDecoration: "none",
          border: "1px solid transparent",

          "&:hover": {
            background: "transparent",
            border: "1px solid #ffffff",
          },

          "&:active, &.active": {
            border: "1px solid #ffffff",
            background: "#ffffff",
            color: "#0d9488",
            fill: "#0d9488",
          },
        },
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
};

export default NavbarItem;
