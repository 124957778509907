import { useState, useEffect } from "react";

const getViewportSize = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

const useViewport = () => {
  const [viewportSize, setViewportSize] = useState(getViewportSize());

  useEffect(() => {
    function handleResize() {
      setViewportSize(getViewportSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isPortrait = !!(viewportSize.height > viewportSize.width) || false;

  return { ...viewportSize, isPortrait: isPortrait };
};

export default useViewport;
