import apiRequest from "../apiRequest";

const ENDPOINT = "datatableIvts";

const list = async ({
  client_id,
  rowsPerPage = null,
  page = 0,
  enableIteration = false,
}) => {
  const pagination = !!rowsPerPage
    ? {
        pagination: {
          rowsPerPage: rowsPerPage,
          page: page,
        },
      }
    : {};

  const isIteration = enableIteration ? {} : { is_iterasi: 1 };

  let body = {
    client_id,
    ...isIteration,
    ...pagination,
  };

  try {
    const res = await apiRequest("post", ENDPOINT, body, 900000);
    return { status: true, data: res.data };
  } catch {
    return { status: false, data: [] };
  }
};

export default list;
