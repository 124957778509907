import { useCallback, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import guestAPI from "@api/guestAPI";
import Button from "@components/Button";
import QrScanner from "@features/QrScanner";
import playSound from "@helper/playSound";
import { setSessionStorage } from "@helper/sessionStorage";

const Scan = ({
  auth,
  enabled,
  guestdata,
  noPrint = false,
  setFormData,
  setSnackbar,
}) => {
  const [scannedQr, setScannedQr] = useState(null);
  const [lastScannedQr, setLastScannedQr] = useState(null);
  const [sameScanCount, setSameScanCount] = useState(0);

  useEffect(() => {
    if (!!noPrint) {
      setSessionStorage("noprint", 1);
    }
  }, []);

  useEffect(
    () => handleScannedCb(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scannedQr]
  );

  useEffect(() => {
    sameScanCount >= 2 && setScannedQr(null);
  }, [sameScanCount]);

  const handleScan = (qrcode) => {
    setScannedQr(qrcode);
    setSameScanCount((prev) => prev + 1);
  };

  const handleScanned = async (disabled, guestdata, qrcode) => {
    if (!disabled && !!qrcode) {
      try {
        let result = {};

        /* Use UUID from guestdata if already loaded */
        if (guestdata.length > 0) {
          result = guestdata?.find((data) => data.ivts_Uuid === qrcode) || {};
          if (!!result) result = { ivts_Uuid: result?.ivts_Uuid };
        } else {
          result = await guestAPI.info(auth.clientId.toString(), qrcode);
        }

        if (!!result.ivts_Uuid) {
          setFormData(result);
        } else {
          throw false;
        }
      } catch {
        playSound("deny");

        setLastScannedQr(qrcode);

        setSnackbar({
          open: true,
          variant: "warning",
          message: "Data tamu tidak ditemukan",
        });
      }

      setSameScanCount(0);
    }
  };

  const handleScannedCb = useCallback(
    () =>
      handleScanned(
        !enabled,
        guestdata,
        scannedQr,
        lastScannedQr,
        sameScanCount
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled, guestdata, scannedQr, lastScannedQr, sameScanCount]
  );

  const onClick = () => {
    setFormData("n/a");
  };

  return (
    <Stack
      direction="column"
      sx={{
        height: "75vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <QrScanner onScanned={handleScan} setSnackbar={setSnackbar} />

      <Button
        fontSize="1.25rem"
        style={{ marginBottom: "0" }}
        onClick={onClick}
      >
        Daftar Manual
      </Button>
    </Stack>
  );
};

export default Scan;
