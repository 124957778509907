import { itemCount } from "@helper/giftHelper";
import apiRequest from "../apiRequest";

const ENDPOINT = "guestConfirmation";

const confirm = async (client_id, param, operator, timestamp, qrCode) => {
  let confirmParam = {
    qrCode: qrCode,
    guest_count: param.guest_count || 0,
    souvenir_count: param.souvenir_count || 0,
    angpau_count: param.angpau_count || 0,
    gift: param.gift || "",
    gift_count: itemCount(param.gift),
    client_id: client_id,
    operator: operator,
    timestamp: timestamp,
  };

  const response = await apiRequest("post", ENDPOINT, confirmParam, 10000);

  if (response.status !== true && !response.label.includes("registered")) {
    throw "CONN";
  }

  return confirmParam;
};

export default confirm;
