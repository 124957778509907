import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";

import Button from "./Button";

const IconButton = (props) => {
  const { children, label, badgeContent, badgeInit, sx, countId, ...rest } =
    props;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={0.5}
      sx={{ width: "100%" }}
    >
      <Button
        sx={{
          position: "relative",
          width: "min-content",
          backgroundColor: "#ffffff",
          color: "#727272",
          borderColor: "#bdbdbd",

          "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
          "& .BaseBadge-badge": {
            top: "-0.5rem",
            right: "-1rem",
            fontSize: "0.75rem",
            fontWeight: "normal",
            padding: "11px 6px",
          },

          "&:hover": {
            backgroundColor: "#f3f3f3",
          },
          ...sx,
        }}
        {...rest}
      >
        <Badge
          badgeContent={badgeContent}
          sx={{
            position: "initial",

            "& .BaseBadge-badge": {
              position: "absolute",
              top: "0.25rem",
              right: "0.35rem",
              color: "#ffffff",
              backgroundColor:
                badgeInit === badgeContent ? "#707070" : "#cd5858",
            },
          }}
        >
          {children}
        </Badge>
      </Button>

      <Typography sx={{ fontSize: "0.75rem", color: "#727272" }}>
        {label}
      </Typography>
    </Stack>
  );
};

export default IconButton;
