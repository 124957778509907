import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import PaidIcon from "@mui/icons-material/Paid";

import Button from "@components/Button";
import * as Input from "@components/Input";

import { confirmInputs } from "./Confirm.const";
import ButtonGroup from "@components/Input/ButtonGroup";

const ConfirmForm = ({
  type,
  guestInfo,
  inputValues,
  error,
  isLoading,
  onChange,
  onSubmit,
}) => {
  const isPresent = guestInfo.ivts_RsvpRespond?.toLowerCase().includes("hadir");
  const isUnconfirmed = guestInfo.ivts_RsvpRespond === "Belum Konfirmasi";
  const isAttend = !!guestInfo.ivts_GuestAttTime;
  const isAbsent = guestInfo.ivts_RsvpRespond === "Tidak Hadir";
  const isRegister = type === "register";

  return (
    <Box
      component="form"
      sx={{
        height: "inherit",
        width: " 100%",
        margin: "0.5rem auto",
        display: "flex",
        flexDirection: "column",
      }}
      onSubmit={onSubmit}
    >
      <Stack gap={2} sx={{ userSelect: "none" }}>
        {type === "register" ? (
          <>
            <Input.Text
              label="Nama Tamu"
              id="guest_name"
              placeholder="Masukkan nama tamu"
              value={inputValues["guest_name"]}
              onChange={onChange}
              error={error["guest_name"]}
            />

            <Input.Text
              label="Alamat/Instansi"
              id="guest_addr"
              placeholder="Masukkan alamat tamu"
              value={inputValues["guest_addr"]}
              onChange={onChange}
              error={error["guest_addr"]}
            />

            <Divider
              sx={{
                display: { xs: "none", md: "block" },
                width: "100%",
                margin: "0.25rem auto",
              }}
            />
          </>
        ) : (
          <></>
        )}

        {confirmInputs.map((input, index) => {
          return input.type === "numeric" ? (
            <Input.Numeric
              key={"numeric_" + index}
              id={input.id}
              label={input.label}
              helper={
                isAttend
                  ? input.helper + " : " + guestInfo[input.attValue]
                  : null
              }
              addition={
                input.id === "guest_count"
                  ? isUnconfirmed
                    ? " / Belum Konfirmasi"
                    : isAbsent
                    ? " / Tidak Hadir"
                    : isPresent
                    ? ` / ${guestInfo[input.rsvpValue] || 0} Pax`
                    : ""
                  : 0
              }
              value={
                input.id === "guest_count" && (isUnconfirmed || isAbsent)
                  ? 0
                  : Number(inputValues[input.id]) || 0
              }
              onChange={onChange}
              disabled={true}
            />
          ) : (
            <ButtonGroup
              id={input.id}
              key={"buttongroup_" + index}
              label={input.label}
              defaultValue={guestInfo[input.rsvpValue]}
              onClick={onChange}
              buttons={buttons}
            />
          );
        })}

        <Button
          size="large"
          type="submit"
          loading={isLoading}
          style={{ marginTop: "1rem" }}
        >
          {isRegister ? "Register" : "Konfirmasi"}
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmForm;

const buttons = [
  { countId: "transfer", label: "Transfer", icon: <PaidIcon /> },
  { countId: "teapai", label: "Tea Pai", icon: <EmojiFoodBeverageIcon /> },
  { countId: "kado", label: "Kado", icon: <CardGiftcardIcon /> },
  { countId: "bunga", label: "Bunga", icon: <LocalFloristIcon /> },
];
