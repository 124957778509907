export const getSessionStorage = (key, defaultValue) => {
  const store = sessionStorage.getItem(key);
  if (!store) {
    return defaultValue;
  } else {
    return JSON.parse(store);
  }
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const clearSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};
