import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Badge from "@components/Badge";
import * as guestHelper from "@helper/guestHelper";
import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const BadgeStack = ({ seat, member, category }) => {
  const { width } = useViewport();

  return (
    <Stack
      gap={1}
      sx={{
        width: width > breakPoints.md ? "40%" : "100%",
        flexWrap: width > breakPoints.md ? "nowrap" : "wrap",
        "& .badge-container": {
          width: width < breakPoints.sm ? "max-content" : "min-content",
          maxWidth: width < breakPoints.sm ? "100%" : "min-content",
          minWidth: "0",
        },
      }}
      direction={width > breakPoints.md ? "column" : "row"}
      justifyContent="flex-start"
      alignItems={width > breakPoints.md ? "flex-end" : "flex-start"}
    >
      {member}
      {width > breakPoints.md ? (
        <Stack
          direction="row"
          gap={1}
          flexWrap="nowrap"
          sx={{
            width: width < breakPoints.sm ? "100%" : "auto",
          }}
        >
          {category}
          {seat}
        </Stack>
      ) : (
        <>
          {category}
          {seat}
        </>
      )}
    </Stack>
  );
};

const ResultCard = ({ item, setData }) => {
  const { width } = useViewport();

  const onClick = () => {
    setData({ ivts_Uuid: item.ivts_Uuid });
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        width: "95%",
        margin: "0.25rem auto",
        padding: "0.5rem",
        background: "#f9e9c1",
        filter: !!item.ivts_GuestAttTime ? "contrast(0.5)" : "contrast(1)",
        borderRadius: "0.25rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "relative",
          height: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0.5rem",

          "@media (max-width: 768px)": {
            flexDirection: "column",
            height: "max-content",
            padding: "0.25rem",
          },
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: width > breakPoints.md ? "0" : "0.25rem",
          }}
        >
          <Typography
            component="span"
            sx={{
              marginTop: "0.15rem",
              fontSize: "1.25rem",
              fontWeight: "bold",
              color: "#0d9488",

              "@media (max-width: 768px)": {
                marginTop: " 0rem",
                marginBottom: "0.15rem",
              },
            }}
          >
            {item.ivts_Name}
          </Typography>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "0.15rem",
              fontSize: "small",
              fontWeight: "normal",
              color: "#808080",

              "@media (max-width: 768px)": {
                marginBottom: "0.25rem",
              },
            }}
          >
            <LocationOnIcon fontSize="0.75rem" />

            <Typography
              component="span"
              variant="body"
              sx={{ marginLeft: "0.25rem" }}
            >
              {item.ivts_Address || " "} ( {item.ivts_NoHp || "-"} )
            </Typography>
          </Stack>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "0.15rem",
              fontSize: "small",
              fontWeight: "normal",
              color: "#808080",

              "@media (max-width: 768px)": {
                marginBottom: "0.25rem",
              },
            }}
          >
            <GroupIcon fontSize="0.75rem" />

            <Typography
              component="span"
              variant="body"
              sx={{ marginLeft: "0.25rem" }}
            >
              {item.ivts_GroupFam || " "} ( {item.ivts_GroupSub || "-"} )
            </Typography>
          </Stack>
        </Stack>

        <BadgeStack
          category={
            <Badge
              label={
                item.ivts_RsvpStatus.includes("Manual")
                  ? item.ivts_RsvpStatus
                  : item.ivts_Category || "N/A"
              }
              color={guestHelper.badgeColor(item.ivts_Category)}
            />
          }
          member={
            !!item.ivts_Anggota ? (
              <Badge
                label={item.ivts_Anggota}
                variant="outlined"
                color="green"
              />
            ) : null
          }
          seat={
            <Badge
              label={item.ivts_Seat || "N/A"}
              variant="outlined"
              color={guestHelper.badgeColor(item.ivts_Seat)}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default ResultCard;
