import React from "react";

import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { default as MuiButton } from "@mui/material/Button";
import Loader from "../Loader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#05aa9c",
      light: "#03a798",
      contrastText: "#f0f0f0",
      dark: "#1d756f",
    },
    secondary: {
      main: "#f9e9c1",
      contrastText: "#adaca8",
      dark: "#888888",
    },
  },
});

const Button = (props) => {
  const { children, variant, fullWidth, loading, size, sx, ...rest } = props;

  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        {...rest}
        variant={variant || "contained"}
        size={size || "large"}
        fullWidth={fullWidth || true}
        disabled={!!loading}
        disableElevation
        sx={{
          padding: "0.75rem 0",
          marginTop: "1rem",
          textTransform: "none",
          color:
            !!!variant || variant === "contained"
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main,
          border: "1px solid",
          borderColor: !!loading
            ? theme.palette.secondary.dark
            : !!!variant || variant === "contained"
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
          ...sx,
        }}
      >
        {!!!loading ? children : <Loader />}
      </MuiButton>
    </ThemeProvider>
  );
};

export default Button;

export const IconButton = styled(Box)(({ color, hoverColor }) => ({
  width: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.5rem 0.25rem",
  color: color || "inherit",
  textAlign: "center",
  fontWeight: "bold",
  cursor: "pointer",

  "&:hover": {
    color: hoverColor,
  },

  "&:active": {
    color: hoverColor,
  },
}));
