import welcome from "../assets/sound/welcome.wav";
import confirm from "../assets/sound/confirm.wav";
import deny from "../assets/sound/deny.wav";

const playSound = (type) => {
  let audio = null;

  switch (type) {
    case "welcome":
      audio = new Audio(welcome);
      break;
    case "confirm":
      audio = new Audio(confirm);
      break;
    case "deny":
      audio = new Audio(deny);
      break;
    default:
      audio = new Audio(welcome);
      break;
  }

  audio.loop = false;
  audio.play();
};

export default playSound;
