export const confirmInputs = [
  {
    id: "guest_count",
    label: "Jumlah Tamu",
    rsvpValue: "ivts_RsvpGuest",
    attValue: "ivts_GuestAtt",
    helper: "Tamu telah registrasi",
    type: "numeric",
  },
  {
    id: "souvenir_count",
    label: "Jumlah Souvenir",
    rsvpValue: "ivts_Souvenir",
    attValue: "ivts_SouvenirAtt",
    helper: "Souvenir diberikan",
    type: "numeric",
  },
  {
    id: "angpau_count",
    label: "Jumlah Angpau",
    rsvpValue: "ivts_Angpau",
    attValue: "ivts_AngpauAtt",
    helper: "Angpau diterima",
    type: "numeric",
  },
  {
    id: "gift",
    label: "Hadiah",
    rsvpValue: "ivts_Gift",
    attValue: "ivts_GiftDesc",
    helper: "Hadiah diterima",
    type: "buttongroup",
  },
];
