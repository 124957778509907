/**
 * TSPL Command Protocol
 * Refference : https://fs.tscprinters.com/system/files/tspl_tspl2_programming_2022_7_4.pdf
 */

import { btPrint } from "@services/btprinter";
import { timestamp } from "@helper/dateTimeHelper";

const BAR = "---";

const printAllLabel = async (printer, setPrinter, setSnackbar, param) => {
  await btPrint(printer, setPrinter, getPrn("table", param))
    .then((device) => {
      if (!!device.uuid) {
        btPrint(device, null, getPrn("angpau", param));
      } else {
        setSnackbar({
          open: true,
          variant: "error",
          message: "Printer tidak terkoneksi",
        });
      }
    })
    .catch(() => {
      /* No print mode */
    });
};

export default printAllLabel;

export const printLabel = async (
  type,
  printer,
  setPrinter,
  setSnackbar,
  param
) => {
  await btPrint(printer, setPrinter, getPrn(type, param))
    .then((device) => {
      if (!!!device.uuid) {
        setSnackbar({
          open: true,
          variant: "error",
          message: "Printer tidak terkoneksi",
        });
      }
    })
    .catch(() => {
      /* No print mode */
    });
};

const getPrn = (type, param) => {
  let prn = "";

  if (type === "test") {
    prn = prnTest(param);
  } else if (type === "table") {
    prn = prnTable(param);
  } else {
    prn = prnAngpau(param);
  }

  return prn;
};

const putText = (x, y, height, text, maxLength) => {
  let nextLine = y;
  let block = "";

  if (!!!text || text.length === 0) {
    return text;
  }

  for (let i = 0; i < text.length; i = i + maxLength) {
    let str = text.slice(i, i + maxLength).trim();
    block = `${block}TEXT ${x},${nextLine},"3",0,1,1,"${str}"\n\r`;

    nextLine = nextLine + height;
  }

  return { block, nextLine };
};

const putBar = (y) => {
  const BAR_HEIGHT = 10;
  let nextLine = y + BAR_HEIGHT;
  let block = "";

  block = `BAR 0,${nextLine},600,3\n\r`;

  nextLine = nextLine + BAR_HEIGHT + BAR_HEIGHT;

  return { block, nextLine };
};

const generatePrn = (x, y, height, template, maxLength) => {
  let textBlocks = "";
  let line = y;

  template.forEach((text) => {
    const { block, nextLine } = text.includes(BAR)
      ? putBar(line)
      : putText(x, line, height, text, maxLength);

    textBlocks = textBlocks + block;
    line = nextLine;
  });

  return (
    "SIZE 80 mm,50 mm\n\r" +
    "GAP 3 mm,0\n\r" +
    "DIRECTION 0\n\rCLS\n\r" +
    textBlocks +
    "PRINT 1\u000A\u000D"
  );
};

const prnTable = (param) => {
  let template = [];
  template.push(
    param.ivts_Name?.toUpperCase() || param.guest_name?.toUpperCase()
  );

  template.push(`Table : ${param.ivts_Seat || "-"}`);

  template.push(`${param.ivts_Category || "-"}`);

  template.push(BAR);

  template.push(
    `${param.guest_count + Number(param.ivts_GuestAtt || "0")}  ${
      param.ivts_RsvpStatus?.includes("Input Manual")
        ? `/ ${param.ivts_RsvpGuest || "-"} Pax`
        : param.ivts_RsvpRespond?.includes("Tidak Hadir")
        ? `/ Tidak Hadir`
        : `/ ${
            param.ivts_RsvpRespond?.includes("Belum Konfirmasi")
              ? "Belum Konfirmasi"
              : `${param.ivts_RsvpGuest || "-"} Pax`
          }`
    }`
  );

  template.push(`${param.ivts_Group || "-"}`);

  template.push(BAR);

  //const souvenir = param.souvenir_count + Number(param.ivts_SouvenirAtt);

  template.push(
    `Registrasi Ke  : ${
      param.ivts_GuestAttTime ? Number(param.ivts_TotalAtt) + 1 : 1
    }`
  );

  return generatePrn(25, 25, 35, template, 40);
};

const prnAngpau = (param) => {
  let template = [];
  template.push(
    param.ivts_Name?.toUpperCase() || param.guest_name?.toUpperCase()
  );

  template.push(BAR);

  template.push(`Penerima Tamu : ${param.operator}`);
  template.push(
    `Registrasi ke : ${
      param.ivts_GuestAttTime ? Number(param.ivts_TotalAtt) + 1 : 1
    }`
  );
  !!param.gift && template.push(`${param.gift}`);
  template.push(`${timestamp()}`);

  return generatePrn(25, 25, 35, template, 40);
};

const prnTest = (param) => {
  let template = [];
  template.push("Koneksi printer berhasil");

  template.push(BAR);

  template.push(`Username      : ${param.username}`);
  template.push(`Penerima Tamu : ${param.operator}`);

  return generatePrn(25, 25, 35, template, 40);
};
