import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import BeenhereIcon from "@mui/icons-material/Beenhere";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import SellIcon from "@mui/icons-material/Sell";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import ConfirmForm from "./ConfirmForm";

import Badge from "@components/Badge";
import * as guestHelper from "@helper/guestHelper";

import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const ConfirmBox = (props) => {
  const { type, data } = props;

  const { width } = useViewport();

  return (
    <>
      <Stack gap={1} sx={{ marginBottom: "1.5rem", width: "100%" }}>
        {type === "confirm" ? (
          <>
            <Typography component="span" variant="body1" color="#0d9488">
              Selamat Datang
            </Typography>

            <Typography
              component="h5"
              variant="h5"
              color="#0d9488"
              sx={{
                fontWeight: "bold",
              }}
            >
              {data?.ivts_Name}
            </Typography>
          </>
        ) : (
          <Typography
            component="h4"
            variant="h4"
            color="#0d9488"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
          >
            Registrasi Manual
          </Typography>
        )}
      </Stack>

      {type === "confirm" && (
        <>
          {!!data?.ivts_Anggota && (
            <Badge
              label={data?.ivts_Anggota}
              icon={<BookmarkIcon />}
              variant="outlined"
              color="green"
              sx={{
                width: "auto",
                maxWidth: "100%",
                marginBottom: "0.75rem",
              }}
            />
          )}

          <Stack
            gap={0.5}
            direction={width < breakPoints.sm ? "column" : "row"}
            sx={{
              width: "100%",
              "& .badge-container": {
                width: width < breakPoints.sm ? "auto" : "min-content",
                maxWidth: width < breakPoints.sm ? "100%" : "min-content",
                minWidth: "0",
                marginBottom: "0.5rem",
              },
            }}
          >
            <Badge
              label={data?.ivts_Category}
              icon={<PersonIcon />}
              color={guestHelper.badgeColor(data?.ivts_Category)}
            />

            <Badge
              label={data?.ivts_Seat}
              icon={<SellIcon />}
              variant="outlined"
              color={guestHelper.badgeColor(data?.ivts_Seat)}
            />
          </Stack>

          <Stack
            gap={width < breakPoints.sm ? 0 : 3}
            direction={width < breakPoints.sm ? "column" : "row"}
            sx={{
              color: "#9b9178",
              marginTop: "0.5rem",
              "& .badge-container": {
                "@media screen and (max-width: 768px)": {
                  maxWidth: "none",
                  width: "-webkit-fill-available",
                },
              },
            }}
          >
            <Stack>
              <Badge
                variant="label"
                label={data?.ivts_RsvpRespond || "-"}
                icon={<BeenhereIcon />}
                maxWidth="none"
              />

              <Badge
                variant="label"
                label={data?.ivts_Group || "-"}
                icon={<WatchLaterIcon />}
                maxWidth="none"
              />
            </Stack>

            <Stack>
              <Badge
                variant="label"
                label={data?.ivts_Address || "-"}
                icon={<LocationOnIcon />}
                maxWidth="none"
              />

              <Badge
                variant="label"
                label={
                  (!!data?.ivts_GroupFam
                    ? (data?.ivts_GroupFam || "-") + " | "
                    : "") + (data?.ivts_GroupSub || "-")
                }
                icon={<GroupIcon />}
                maxWidth="none"
              />
            </Stack>
          </Stack>
        </>
      )}

      <ConfirmForm {...props} />
    </>
  );
};

export default ConfirmBox;
