import { recordsGiftPrep } from "@helper/giftHelper";
import guestAPI from "@services/api/guestAPI";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const parseGuestTable = (data) => {
  const table = data.map((item) => {
    item.iterasi_list = recordsGiftPrep(item?.iterasi_list || []);

    const guestAttTime = item.ivts_GuestAttTimeLast || item.ivts_GuestAttTime;

    return {
      ...item,
      ivts_Name: item.ivts_Name?.trim(),
      ivts_GuestAttTime: guestAttTime,
      ivts_GuestAttStatus: !!guestAttTime
        ? "Sudah Registrasi"
        : "Belum Registrasi",
    };
  });

  return table;
};

const useFetchTable = ({ auth, enableIteration = false, onError }) => {
  const loadGuestData = async () => {
    if (!!!auth) return [];

    const res = await guestAPI.list({
      client_id: auth.clientId.toString(),
      enableIteration,
    });

    const data = res.data?.table?.data || res.data?.table || [];

    return data;
  };

  const {
    data = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["guest"],
    queryFn: loadGuestData,
    onError: onError,
    refetchInterval: 30000,
  });

  const table = useMemo(() => {
    return parseGuestTable(data);
  }, [data]);

  return { data: table, refetch, isFetching };
};

export default useFetchTable;
