import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";

import Button from "@components/Button";
import Card from "@components/Card";
import Loader from "@components/Loader";
import DataTable from "@features/DataTable";
import * as guestHelper from "@helper/guestHelper";
import useViewport from "@hooks/useViewport";
import { breakPoints, tableformat, tableRecordsFormat } from "@src/constants";

const shadingStyle = {
  position: "relative",

  "&:before": {
    content: "''",
    width: "3rem",
    height: "100%",
    right: "0",
    bottom: "0",
    margin: "0",
    zIndex: "1",
    position: "absolute",
    background:
      "linear-gradient(270deg, rgba(249,233,193,1)  0%,  rgba(249,233,193,0) 30%)",
  },

  "&:after": {
    content: "''",
    width: "3rem",
    height: "100%",
    left: "0",
    bottom: "0",
    margin: "0",
    zIndex: "1",
    position: "absolute",
    background:
      "linear-gradient(90deg, rgba(249,233,193,1) 0%, rgba(249,233,193,0) 30%)",
  },
};

const Monitoring = ({ auth, guestdata = [], onRefresh, isFetching }) => {
  const [monitoringData, setMonitoringData] = useState([]);
  const [switchState, setSwitchState] = useState(true);
  const scrollRef = useRef(null);
  const { width } = useViewport();

  const isDataEmpty = guestdata.length === 0;

  useEffect(() => {
    isDataEmpty && setMonitoringData(guestdata);
  }, [guestdata]);

  const handleDataChange = (update) => {
    setMonitoringData(update);
  };

  const handleSwitch = (event, state) => {
    setSwitchState(state);
  };

  return (
    <Stack sx={{ width: "100%" }}>
      {isFetching && isDataEmpty ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30rem",
          }}
        >
          <Loader size="10rem" color="#ffffff" />
        </Box>
      ) : isDataEmpty ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "15rem",
            height: "30rem",
            fontSize: "2rem",
            margin: "auto",
            gap: 3,
          }}
        >
          <span>No Data !</span>

          <Button onClick={onRefresh}>Reload</Button>
        </Stack>
      ) : (
        <>
          <Stack
            justifyContent="center"
            alignItems="flex-end"
            sx={{ width: "100%", marginBottom: "0.5rem" }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleSwitch}
                    defaultChecked
                    color="success"
                    sx={{ marginRight: "0.5rem" }}
                  />
                }
                labelPlacement="start"
                label={switchState ? "Show Summary" : "Hide Summary"}
                sx={{ color: "#938a75" }}
              />
            </FormGroup>
          </Stack>

          {width > breakPoints.sm
            ? switchState && (
                <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
                  {guestHelper
                    .summarize(monitoringData)
                    .map((summary, index) => (
                      <Grid key={"monitoringcard_" + index} item xs={6} md={3}>
                        <Card summary={summary} />
                      </Grid>
                    ))}
                </Grid>
              )
            : switchState && (
                <Box
                  width="100%"
                  sx={{
                    ...shadingStyle,
                    marginBottom: "1rem",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    ref={scrollRef}
                    width="auto"
                    sx={{
                      padding: "1rem 0rem 1rem 1rem",
                      overflowX: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        width: "max-content",
                        marginRight: "1.5rem",
                      }}
                    >
                      {guestHelper
                        .summarize(monitoringData)
                        .map((summary, index) => (
                          <Card
                            key={"monitoringcard_" + index}
                            summary={summary}
                          />
                        ))}
                    </Stack>
                  </Box>
                </Box>
              )}

          <DataTable
            auth={auth}
            data={guestdata}
            format={tableformat}
            sortBy="ivts_GuestAttTime"
            onDataUpdate={onRefresh}
            onDataChange={handleDataChange}
            recordsFormat={tableRecordsFormat}
            isFetching={isFetching}
          />
        </>
      )}
    </Stack>
  );
};

export default Monitoring;
