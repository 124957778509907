import { useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";

import ResultCard from "./SearchbarResultCard";
import ResultHelper from "./SearchbarResultHelper";

const SearchbarResult = (props) => {
  const {
    onScroll,
    queries,
    results,
    maxResult = 20,
    setData,
    isLoading,
  } = props;

  const [maxCount, setMaxCount] = useState(maxResult);
  const [swipeable, setSwipeable] = useState(false);
  const scrollRef = useRef(null);

  const isAnyQuery = Object.keys(queries).length > 0;
  const isAnyResult = results.length > 0;

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
    setMaxCount(results.length > maxResult ? maxResult : results.length);
  }, [results]);

  const handleScroll = (event) => {
    const position =
      event.target.scrollHeight - Math.floor(event.target.scrollTop);
    const isBottom =
      position < event.target.clientHeight + 2 &&
      position > event.target.clientHeight - 2;

    if (isBottom) {
      if (!swipeable) {
        maxCount < results.length &&
          setTimeout(() => {
            setSwipeable(true);
          }, 250);
      } else {
        setMaxCount((prev) =>
          prev + maxResult > results.length ? results.length : prev + maxResult
        );

        setSwipeable(false);
        scrollRef.current.scrollTop = event.target.scrollTop;
      }
    }

    onScroll(event);
  };

  return (
    <Stack
      ref={scrollRef}
      onScroll={handleScroll}
      gap={0}
      sx={{
        width: "100%",
        margin: "0 auto",
        overflowY: "scroll",
        paddingBottom: "1rem",

        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {isAnyResult ? (
        results.slice(0, maxCount).map((item, index) => {
          return (
            <ResultCard
              key={"result_cards_" + index}
              item={item}
              setData={setData}
            />
          );
        })
      ) : (
        <></>
      )}

      {(isAnyResult || isAnyQuery) && (
        <ResultHelper
          isLoading={isLoading}
          setData={setData}
          resultLength={results.length}
          maxResult={maxCount}
          swipeable={swipeable}
        />
      )}
    </Stack>
  );
};

export default SearchbarResult;
