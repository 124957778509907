import { useEffect, useState } from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";

import BackspaceIcon from "@mui/icons-material/Backspace";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";

import { IconButton } from "@components/Button/Button";
import * as Input from "@components/Input";
import Loader from "@components/Loader";
import { findUnique } from "@helper/itemsHelper";

const SearchInput = styled(InputBase)({
  width: "100%",
  whiteSpace: "nowrap",
  background: "transparent",
  marginRight: "0.5rem",
  padding: " 0 0.5rem",
  fontSize: "inherit",
  border: "none",
  outline: "none",
  color: "inherit",

  "&:focus": {
    border: "none !important",
  },

  "&::placeholder ": {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: "#aba79e",
    opacity: "1" /* Firefox */,
  },
});

const filters = [
  {
    label: "Alamat/Instansi",
    value: "ivts_Address",
  },
  {
    label: "Kategori",
    value: "ivts_GroupFam",
  },
  {
    label: "Sub Kategori",
    value: "ivts_GroupSub",
  },
  {
    label: "Kelas",
    value: "ivts_Category",
  },
];

const SearchbarInput = ({
  inputRef,
  data,
  results,
  searchQueries,
  setSearchQueries,
  onClose,
  onSubmit,
  isLoading = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    inputRef.current.value = "";
    inputRef.current?.focus();

    setInputValue("");
    setSearchQueries({});
  }, []);

  useEffect(() => {
    const getOptions = () => {
      let options = {};

      filters.forEach((filter) => {
        options[filter.value] = findUnique(
          results.length > 0 ? results : data,
          filter.value
        );
      });

      return options;
    };

    setFilterOptions(getOptions());
  }, [data, results]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChange(inputValue);
    }, 1000);

    return () => clearTimeout(timer);
  }, [inputValue]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const handleChange = (value) => {
    const prop = isNaN(value) ? "ivts_Name" : "ivts_NoHp";

    let newFilters = { ...searchQueries };
    if (value !== "") {
      newFilters[prop] = value;
      if (prop === "ivts_Name") {
        newFilters["ivts_Anggota"] = value;
      }
    } else {
      delete newFilters["ivts_Name"];
      delete newFilters["ivts_NoHp"];
      delete newFilters["ivts_Anggota"];
    }

    setSearchQueries(newFilters);
  };

  const handleClear = () => {
    if (!!!inputValue && !!!Object.keys(searchQueries).length) {
      // close on empty input
      onClose();
    } else {
      // 👇️ reset input field's value
      inputRef.current.value = "";

      setInputValue("");
      setSearchQueries({});
    }
  };

  const handleSelected = (key, value) => {
    let newValue = { ...searchQueries };

    if (!!value && value !== "-") {
      newValue[key] = value;
    } else {
      delete newValue[key];
    }

    setSearchQueries(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        margin: " 1rem auto 0.25rem",
        padding: "0.5rem",
        background: "#f9e9c1",
        borderRadius: "0.25rem",
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "100%",
          padding: "0.25rem 0",
          background: "#ffffff",
          color: "#aba79e",
          borderRadius: "0.25rem",
          border: "1px solid #d0cec9",
        }}
      >
        <Box
          sx={{
            width: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isLoading ? <Loader color="inherit" size="1rem" /> : <SearchIcon />}
        </Box>

        <SearchInput
          inputRef={inputRef}
          type="text"
          placeholder={
            isLoading ? "Sedang memuat data..." : "Cari Nama tamu / No. HP" // / Anggota"
          }
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleKeyDown}
        />

        <IconButton hoverColor="#505050" onClick={toggleFilter}>
          <TuneIcon />
        </IconButton>

        <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />

        <IconButton hoverColor="#ff4444" onClick={handleClear}>
          {!!inputValue || Object.keys(searchQueries).length > 0 ? (
            <BackspaceIcon />
          ) : (
            <CloseIcon />
          )}
        </IconButton>
      </Stack>

      <Box
        sx={{
          display: showFilter ? "block" : "none",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          {filters.map((filter) => (
            <Grid item key={"selection_" + filter.value} xs={6}>
              <Input.Selection
                margin="0.5rem auto 0.5rem"
                name={filter.value}
                label={filter.label}
                options={filterOptions[filter.value] || [""]}
                initialValue={searchQueries[filter.value] || null}
                onSelected={handleSelected}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchbarInput;
