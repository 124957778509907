export const breakPoints = {
  xs: 400,
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1192,
};

export const tableformat = [
  {
    header: "Kehadiran",
    value: "ivts_GuestAttStatus",
    type: "checkbox",
    align: "center",
    width: "7.5rem",
    filterable: true,
    options: ["Sudah Registrasi", "Belum Registrasi"],
    searchable: true,
    hide: false,
  },
  {
    header: "Nama Undangan",
    value: "ivts_Name",
    align: "left",
    width: "15rem",
    filterable: true,
    searchable: true,
    hide: false,
  },
  {
    header: "Alamat / Instansi",
    value: "ivts_Address",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: false,
  },
  {
    header: "Kategori",
    value: "ivts_GroupFam",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: true,
  },
  {
    header: "Sub Kategori",
    value: "ivts_GroupSub",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: true,
  },
  {
    header: "Sesi Acara",
    value: "ivts_Group",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: false,
    hide: true,
  },
  {
    header: "Meja",
    value: "ivts_Seat",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: false,
  },
  {
    header: "Kelas",
    value: "ivts_Category",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: false,
  },
  {
    header: "RSVP",
    value: "ivts_RsvpRespond",
    align: "left",
    width: "10rem",
    filterable: true,
    filterExact: true,
    options: ["Hadir", "Tidak Hadir", "Belum Konfirmasi"],
    searchable: true,
    hide: true,
  },
  {
    header: "Anggota",
    value: "ivts_Anggota",
    align: "center",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: true,
  },
  {
    header: "Est. Souvenir",
    value: "ivts_Souvenir",
    align: "center",
    width: "10rem",
    filterable: false,
    searchable: false,
    hide: true,
  },
  {
    header: "Jumlah Tamu",
    value: "ivts_GuestAtt",
    align: "center",
    width: "10rem",
    filterable: false,
    searchable: false,
    hide: true,
  },
  {
    header: "Jumlah Souvenir",
    value: "ivts_SouvenirAtt",
    align: "center",
    width: "10rem",
    filterable: false,
    searchable: false,
    hide: true,
  },
  {
    header: "Jumlah Angpao",
    value: "ivts_AngpauAtt",
    align: "center",
    width: "10rem",
    filterable: false,
    searchable: false,
    hide: true,
  },
  {
    header: "Hadiah",
    value: "ivts_GiftDesc",
    align: "center",
    width: "10rem",
    filterable: true,
    options: ["Transfer", "Tea Pai", "Kado", "Bunga"],
    searchable: true,
    hide: true,
    type: "icon",
  },
  {
    header: "Status Data",
    value: "ivts_RsvpStatus",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: false,
    hide: true,
  },
  {
    header: "Penerima Tamu",
    value: "ivts_GuestAttCounter",
    align: "left",
    width: "10rem",
    filterable: true,
    searchable: true,
    hide: false,
  },
  {
    header: "Waktu Hadir",
    value: "ivts_GuestAttTime",
    align: "left",
    width: "10rem",
    filterable: false,
    searchable: false,
    hide: false,
  },
];

export const tableRecordsFormat = {
  id: "iterasi_list",
  column: [
    { title: "Registrasi Ke", type: "index" },
    { title: "Jumlah Tamu", value: "ivts_GuestAtt" },
    { title: "Jumlah Souvenir", value: "ivts_SouvenirAtt" },
    { title: "Jumlah Angpau", value: "ivts_AngpauAtt" },
    { title: "Hadiah", value: "ivts_GiftDesc", type: "icon" },
    { title: "Waktu CheckIn", value: "ivts_GuestAttTime" },
    { title: "Kode Counter", value: "ivts_GuestAttCounter" },
  ],
};

export const giftFormat = [
  { countId: "transfer", label: "Transfer" },
  { countId: "teapai", label: "Tea Pai" },
  { countId: "kado", label: "Kado" },
  { countId: "bunga", label: "Bunga" },
];
