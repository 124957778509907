import React from "react";

import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";

import FormLogin from "./FormLogin";
import FormOperator from "./FormOperator";
import FormConfirm from "./Confirm";

import { IconButton } from "@components/Button";
import FormEnablePrint from "./FormEnablePrint";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Form = (props) => {
  return (
    <Dialog
      scroll="body"
      open={props.show}
      fullWidth={true}
      maxWidth="sm"
      TransitionComponent={Transition}
      sx={{
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          background: "#f9e9c1",
          boxShadow: "0 0 10px 3px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.5rem",
          display: "flex",

          width: "100%",
          height: "auto",
          overflow: "hidden",

          "@media screen and (max-width: 600px)": {
            height: "95%",
          },
        }}
      >
        <Box
          sx={{
            width: "90%",
            margin: "0.5rem auto 0",
            padding: "1rem",
          }}
        >
          {!!props.data?.operator ? (
            <IconButton
              color="#404040"
              hoverColor="#ff4444"
              onClick={props.onClose}
              sx={{ position: "absolute", top: "0.5rem", right: "1rem" }}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <></>
          )}
          {props.type === "login" ? (
            <FormLogin {...props} />
          ) : props.type === "operator" ? (
            <FormOperator {...props} />
          ) : props.type === "printer" ? (
            <FormEnablePrint {...props} />
          ) : (
            <FormConfirm {...props} />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Form;
