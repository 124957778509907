import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CloudOffIcon from "@mui/icons-material/CloudOff";

import Button from "@components/Button";

const RetryConnBox = ({ fetchInfo, isLoading }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-around"
      gap={1}
      sx={{ minHeight: "20rem" }}
    >
      <Stack alignItems="center" gap={3}>
        <CloudOffIcon sx={{ color: "#0d9488", fontSize: "4rem" }} />
        <Typography
          component="h5"
          variant="h5"
          sx={{
            color: "#0d9488",
            textAlign: "center",
          }}
        >
          Koneksi Bermasalah
        </Typography>
      </Stack>

      <Button loading={isLoading} onClick={fetchInfo}>
        Coba Lagi
      </Button>
    </Stack>
  );
};

export default RetryConnBox;
