import itemCount from "./giftHelper";

export const badgeColor = (text) => {
  let color = "";

  text = !!text ? text.toLowerCase() : "none";

  switch (text) {
    case "reg":
    case "Reg":
    case "regular":
    case "reguler":
      color = "blue";
      break;
    case "keluarga":
      color = "green";
      break;
    case "vip":
      color = "red";
      break;
    case "gold":
    case "vvip":
      color = "yellow";
      break;
    case "panitia":
      color = "turquoise";
      break;
    default:
      color = "gray";
      break;
  }

  return color;
};
export const summarize = (data) => {
  let result = [];
  let sum = {
    rsvp: {
      total: 0,
      present: 0,
      invitations: 0,
      absence: 0,
      unconfirmed: 0,
    },
    registered: {
      present: 0,
      guests: 0,
      souvenir: 0,
      angpau: 0,
      gift: 0,
    },
    manual: {
      present: 0,
      guests: 0,
      souvenir: 0,
      angpau: 0,
      gift: 0,
    },
    total: {
      present: 0,
      guests: 0,
      souvenir: 0,
      angpau: 0,
      gift: 0,
    },
  };

  if (!!data.length) {
    data.forEach((row) => {
      /* RSVP */
      if (
        row.ivts_RsvpStatus === "Undangan" ||
        row.ivts_RsvpStatus === "RSVP"
      ) {
        sum.rsvp.total++;

        if (!!row.ivts_RsvpRespond?.includes("Hadir(")) {
          sum.rsvp.invitations++;

          sum.rsvp.present += Number(
            row.ivts_RsvpRespond.replace(/^\D+|\)/g, "")
          );
        } else if (!!row.ivts_RsvpRespond?.includes("Tidak")) {
          sum.rsvp.absence++;
        } else if (!!row.ivts_RsvpRespond?.includes("Belum")) {
          sum.rsvp.unconfirmed++;
        }
      }

      if (
        !!row.ivts_RsvpStatus?.includes("Undangan") ||
        !!row.ivts_RsvpStatus?.includes("RSVP") ||
        !!row.ivts_RsvpStatus?.includes("Input Manual")
      ) {
        /* Total */
        if (
          !!row.ivts_GuestAttTime ||
          !!row.ivts_RsvpStatus?.includes("Input Manual")
        ) {
          sum.total.present++;
        }
        sum.total.guests += Number(row.ivts_GuestAtt);
        sum.total.souvenir += Number(row.ivts_SouvenirAtt);
        sum.total.angpau += Number(row.ivts_AngpauAtt);
        sum.total.gift += itemCount(row.ivts_GiftDesc);
      }

      if (
        !!row.ivts_RsvpStatus?.includes("Undangan") ||
        !!row.ivts_RsvpStatus?.includes("RSVP")
      ) {
        /* Registered */
        if (!!row.ivts_GuestAttTime) {
          sum.registered.present++;
        }
        sum.registered.guests += Number(row.ivts_GuestAtt);
        sum.registered.souvenir += Number(row.ivts_SouvenirAtt);
        sum.registered.angpau += Number(row.ivts_AngpauAtt);
        sum.registered.gift += itemCount(row.ivts_GiftDesc);
      } else {
        /* Manual */
        sum.manual.present++;
        sum.manual.guests += Number(row.ivts_GuestAtt);
        sum.manual.souvenir += Number(row.ivts_SouvenirAtt);
        sum.manual.angpau += Number(row.ivts_AngpauAtt);
        sum.manual.gift += itemCount(row.ivts_GiftDesc);
      }
    });
  }

  result = [
    {
      title: "Hasil RSVP",
      background: "#5c9cc9",
      data: [
        {
          label: "Daftar Undangan",
          value: sum.rsvp.total,
          icon: "list",
        },
        {
          label: "Und. Hadir",
          value: sum.rsvp.invitations,
          icon: "present",
        },
        {
          label: "Tamu Hadir",
          value: sum.rsvp.present,
          icon: "person",
        },
        {
          label: "Und. Tidak Hadir",
          value: sum.rsvp.absence,
          icon: "envelopeoff",
        },
        {
          label: "Und. Belum Konfirmasi",
          value: sum.rsvp.unconfirmed,
          icon: "envelope",
        },
      ],
    },
    {
      title: "Hasil Registrasi",
      background: "#cd5858",
      data: [
        { label: "Hadir", value: sum.total.present, icon: "present" },
        {
          label: "Tamu",
          value: sum.total.guests,
          icon: "person",
        },
        {
          label: "Souvenir",
          value: sum.total.souvenir,
          icon: "souvenir",
        },
        { label: "Angpau", value: sum.total.angpau, icon: "angpau" },
        { label: "Hadiah", value: sum.total.gift, icon: "gift" },
      ],
    },
    {
      title: "Data Undangan",
      background: "#3b9659",
      data: [
        { label: "Hadir", value: sum.registered.present, icon: "present" },
        {
          label: "Tamu",
          value: sum.registered.guests,
          icon: "person",
        },
        {
          label: "Souvenir",
          value: sum.registered.souvenir,
          icon: "souvenir",
        },
        { label: "Angpau", value: sum.registered.angpau, icon: "angpau" },
        { label: "Hadiah", value: sum.registered.gift, icon: "gift" },
      ],
    },
    {
      title: "Input Manual",
      background: "#3b9659",
      data: [
        { label: "Hadir", value: sum.manual.present, icon: "present" },
        {
          label: "Tamu",
          value: sum.manual.guests,
          icon: "person",
        },
        {
          label: "Souvenir",
          value: sum.manual.souvenir,
          icon: "souvenir",
        },
        { label: "Angpau", value: sum.manual.angpau, icon: "angpau" },
        { label: "Hadiah", value: sum.manual.gift, icon: "gift" },
      ],
    },
  ];

  return result;
};
