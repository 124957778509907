import apiRequest from "../apiRequest";

const ENDPOINT = "searchGuest";

const search = async ({
  client_id,
  query,
  timeout = 10000,
  enableIteration = true,
}) => {
  let reply = [];

  const iterasi = enableIteration ? {} : { is_iterasi: 1 };

  const response = await apiRequest(
    "post",
    ENDPOINT,
    { client_id, ...query, ...iterasi },
    timeout
  );

  if (!response?.status && !response.label?.includes("not found")) {
    throw "CONN";
  } else {
    reply = response.data;
  }

  return reply;
};

export default search;
