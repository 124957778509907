import { useRef } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import useIsOverflow from "@hooks/useIsOverflow";

const badgeColor = {
  base: "#f9e9c1",
  blue: "#3890eb",
  green: "#509b50",
  red: "#df6262",
  yellow: "#ffb84d",
  turquoise: "#00a3a6",
  gray: "#8d8d8d",
  default: "#ffffff",
  none: "transparent",
};

const textTransform = (text) => {
  text = text.toLowerCase();

  switch (text) {
    case "vvip":
    case "svip":
    case "vip":
      return "uppercase";
    default:
      return "capitalize";
  }
};

const Badge = ({
  label,
  icon = null,
  variant = "contained",
  color,
  width = "min-content",
  maxWidth = "10rem",
  sx = {},
}) => {
  const badgeRef = useRef(null);
  const isOverflow = useIsOverflow(badgeRef);

  label = label?.toLowerCase() || "N/A";

  return (
    <Stack
      className="badge-container"
      direction="row"
      sx={{
        width: width,
        maxWidth: maxWidth,
        padding: variant === "label" ? "0.25rem 0rem" : "0.25rem 0.5rem",
        borderRadius: "0.5rem",
        background: variant === "contained" ? badgeColor[color] : "transparent",
        border:
          variant === "contained" ? "none" : `1px solid ${badgeColor[color]}`,

        "& .MuiSvgIcon-root": {
          fontSize: "1.25rem",
          fill:
            variant === "contained"
              ? badgeColor.base
              : variant === "outlined"
              ? badgeColor[color]
              : badgeColor.gray,
        },
        ...sx,
      }}
    >
      {icon}
      <Typography
        ref={badgeRef}
        component="span"
        variant="subtitle2"
        sx={{
          color: variant === "contained" ? badgeColor.base : badgeColor[color],
          marginLeft: !!icon ? "0.5rem" : "0 auto",
          textTransform: textTransform(label),
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {isOverflow ? (
          <Marquee speed={80} gradientWidth={0}>
            <span
              style={{
                marginLeft: "2rem",
                fontWeight: "500",
              }}
            >
              {label}
            </span>
          </Marquee>
        ) : (
          <>{label}</>
        )}
      </Typography>
    </Stack>
  );
};

export default Badge;
