import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ResultHelper = (props) => {
  const { setData, resultLength, maxResult, swipeable, isLoading } = props;

  const handleClick = (event) => {
    event.preventDefault(event);
    setData("n/a");
  };

  return (
    <>
      <Box
        sx={{
          width: "95%",
          margin: "0.5rem auto",
          padding: "0.75rem 0.5rem",
          background: "#f9e9c1",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "0.15rem",
          fontSize: "small",
          fontWeight: "normal",
          color: "#808080",
          textAlign: "center",
          borderRadius: "0.25rem",

          "@media (max-width: 768px)": {
            marginBottom: "0.25rem",
          },
        }}
      >
        <Typography
          variant="body2"
          component="span"
          sx={{ padding: "0.5rem 0" }}
        >
          {maxResult < resultLength ? (
            <span>Swipe keatas untuk tampilkan lebih banyak</span>
          ) : isLoading ? (
            <span>Memuat data...</span>
          ) : (
            <span>
              Nama tidak ditemukan?{" "}
              <a
                href="/"
                onClick={handleClick}
                style={{ textDecoration: "underline" }}
              >
                klik disini
              </a>{" "}
              untuk registrasi manual
            </span>
          )}
        </Typography>
      </Box>

      {swipeable &&
        (maxResult < resultLength ? (
          <Stack
            alignItems="center"
            sx={{
              width: "100%",
              marginTop: "1rem",
              paddingBottom: "5em",
            }}
          >
            <KeyboardArrowUpIcon
              sx={{
                margin: "-0.75rem",
                fontSize: "3rem",
                color: "white",
                opacity: "25%",
              }}
            />
            <KeyboardArrowUpIcon
              sx={{
                margin: "-0.75rem",
                fontSize: "3rem",
                color: "white",
                opacity: "50%",
              }}
            />
            <KeyboardArrowUpIcon
              sx={{ margin: "-0.75rem", fontSize: "3rem", color: "white" }}
            />
          </Stack>
        ) : (
          <Box sx={{ width: "95%", paddingBottom: "10rem" }} />
        ))}
    </>
  );
};

export default ResultHelper;
