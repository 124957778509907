import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { values2string, string2values } from "@helper/giftHelper";
import IconButton from "../Button/IconButton";

const ButtonGroup = ({ id, label, buttons, defaultValue = "", onClick }) => {
  const [counts, setCounts] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCounts(string2values(defaultValue, buttons)), []);

  const handleClick = (countId) => {
    let updatedCounts = {
      ...counts,
      [countId]: Number(counts[countId] || 0) + 1,
    };

    onClick({
      target: { id: id, value: values2string(updatedCounts, buttons) },
    });
    setCounts(updatedCounts);
  };

  const handleReset = () => {
    onClick({
      target: { id: id, value: defaultValue },
    });
    setCounts(string2values(defaultValue, buttons));
  };

  const defaultCounts = string2values(defaultValue, buttons);

  return (
    <Stack
      sx={{
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",

        "@media screen and (max-width: 768px)": {
          alignItems: "flex-start",
          flexDirection: "column",
          fontSize: "1rem",
        },
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: "1.5rem",
          flexDirection: "row",
          justifyContent: "space-between",

          "@media screen and (min-width: 768px)": {
            width: "min-content",
            flexDirection: "column",
            gap: "0.15rem",
          },
        }}
      >
        <Typography
          variant="h6"
          component="span"
          color="#747474"
          sx={{
            fontSize: "1.15rem",

            "@media screen and (max-width: 768px)": {
              fontSize: "1rem",
            },
          }}
        >
          {label || "Unlabeled"}
        </Typography>

        <Link
          href="#"
          onClick={handleReset}
          variant="body2"
          sx={{
            color: "#cd5858",
            textDecoration: "none",
            padding: "0.15rem 0.25rem",
            border: "1px solid #cd5858",
            borderRadius: "0.25rem",
            textAlign: "center",
            background: "transparent",

            "&:hover": {
              color: "#cd5858",
              textDecoration: "none",
              background: "#cd585810",
            },

            "&:active": {
              color: "#cd5858",
              textDecoration: "none",
              background: "#cd585850",
            },
          }}
        >
          Reset
        </Link>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          height: "max-content",
          justifyContent: "space-between",
          width: "100%",

          "@media screen and (min-width: 768px)": {
            width: "60%",
          },
        }}
      >
        {buttons.map((button, index) => (
          <IconButton
            key={`button_${index}`}
            countId={button.countId}
            label={button.label}
            badgeInit={defaultCounts[button.countId]}
            badgeContent={counts?.[button.countId]}
            onClick={() => handleClick(button.countId)}
            sx={{
              width: `90%`,
              "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
            }}
          >
            {button.icon}
          </IconButton>
        ))}
      </Stack>
    </Stack>
  );
};

export default ButtonGroup;
