import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Button from "@components/Button";
import { getSessionStorage } from "@helper/sessionStorage";

const SuccessBox = (props) => {
  const { guestName, print, onClose } = props;

  const noPrint = getSessionStorage("noprint", 0);

  return (
    <Stack alignItems="center" gap={1}>
      <CheckCircleIcon sx={{ color: "#0d9488", fontSize: "5rem" }} />

      <Stack alignItems="center" gap={1}>
        <Typography
          component="h4"
          variant="h4"
          sx={{ color: "#0d9488", textAlign: "center" }}
        >
          Registrasi Berhasil
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          sx={{
            color: "#9b9178",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          {guestName}
        </Typography>
      </Stack>

      <Button size="large" onClick={onClose}>
        Kembali
      </Button>

      {noPrint === 0 ? (
        <>
          <Button variant="outlined" onClick={() => print("table")}>
            Re-Print Table Card
          </Button>

          <Button variant="outlined" onClick={() => print("angpau")}>
            Re-Print Angpau Label
          </Button>
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default SuccessBox;
