import FlipIcon from "@mui/icons-material/Flip";
import MonitorIcon from "@mui/icons-material/Monitor";
import SearchIcon from "@mui/icons-material/Search";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import LogoutIcon from "@mui/icons-material/Logout";

import Logo from "../../assets/images/Logo";

import NavbarContainer from "./NavbarContainer";

const navbarItems = [
  { text: "Scan", type: "link", link: "/scan", icon: <FlipIcon /> },
  {
    text: "Monitoring",
    type: "link",
    link: "/monitoring",
    icon: <MonitorIcon />,
  },
  { text: "", type: "action", dispatch: "search", icon: <SearchIcon /> },
  {
    text: "Printer",
    type: "menu",
    dispatch: "printer",
    icon: <BluetoothIcon />,
  },
  { text: "Logout", type: "menu", dispatch: "logout", icon: <LogoutIcon /> },
];

const Navbar = (props) => {
  const { userInfo, onItemClick } = props;

  return (
    <NavbarContainer
      logo={<Logo size="4rem" />}
      userInfo={userInfo}
      items={navbarItems}
      onItemClick={onItemClick}
    />
  );
};
export default Navbar;
