import apiRequest from "../apiRequest";

const ENDPOINT = "scanQRCode";

const info = async (client_id, qrCode) => {
  let reply = [];

  const response = await apiRequest(
    "post",
    ENDPOINT,
    { client_id, qrCode },
    15000
  );

  if (response.message.includes("koneksi") || !response.status) {
    throw "CONN";
  } else {
    reply = response.data;
  }

  return reply;
};

export default info;
