import { getSessionStorage } from "src/helper/sessionStorage";

/* eslint-disable no-loop-func */
const btDeviceSelect = async () => {
  let server;

  await navigator.bluetooth
    .requestDevice({
      filters: [
        {
          services: ["000018f0-0000-1000-8000-00805f9b34fb"],
        },
      ],
    })
    .then((device) => {
      server = device.gatt.connect();
    });

  return server;
};

const btSetup = async (setPrinter, testPrint, alert) => {
  let devcharacteristic = {};

  await btDeviceSelect()
    .then((server) =>
      server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
    )
    .then((service) =>
      service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
    )
    .then((characteristic) => {
      devcharacteristic = characteristic;

      !!testPrint && testPrint(devcharacteristic);
      !!setPrinter && setPrinter(devcharacteristic);
    })
    .catch((error) => {
      console.log("Argh! " + error);
      !!alert && alert("Printer tidak terkoneksi");
    });
  return devcharacteristic;
};

export default btSetup;

const writeToCharacteristicAsBuffer = async (characteristic, str) => {
  var maxChunk = 512;
  var j = 0;

  if (str.length > maxChunk) {
    for (var i = 0; i < str.length; i += maxChunk) {
      var subStr;
      if (i + maxChunk <= str.length) {
        subStr = str.substring(i, i + maxChunk);
      } else {
        subStr = str.substring(i, str.length);
      }

      setTimeout(
        () =>
          writeStrToCharacteristic(characteristic, subStr).catch((err) => {
            throw err;
          }),
        250 * j
      );
      j++;
    }
  } else {
    await writeStrToCharacteristic(characteristic, str).catch((err) => {
      throw err;
    });
  }

  return true;
};

const writeStrToCharacteristic = (characteristic, str) => {
  let buffer = new ArrayBuffer(str.length);
  let dataView = new DataView(buffer);
  for (var i = 0; i < str.length; i++) {
    dataView.setUint8(i, str.charAt(i).charCodeAt());
  }
  return characteristic.writeValue(buffer).catch((err) => {
    throw err;
  });
};

export const btPrint = async (printer, setPrinter, prn) => {
  if (getSessionStorage("noprint", 0) !== 0) throw 0;

  let err = false;

  if (!!printer.uuid) {
    await writeToCharacteristicAsBuffer(printer, prn).catch((e) => {
      err = true;
    });
  } else {
    err = true;
  }

  if (err) {
    await btSetup(setPrinter).then(async (device) => {
      if (!!device.uuid) {
        printer = device;
        setPrinter(printer);
        await writeToCharacteristicAsBuffer(printer, prn).catch((err) => {
          throw err;
        });
      } else {
        printer = {};
      }
    });
  }

  return printer;
};

export const btReconnect = async (
  printer,
  setPrinter,
  testPrint = null,
  alert
) => {
  if (!!printer.name) {
    await navigator.bluetooth
      .getDevices()
      .then((devices) => {
        console.log("> Got " + devices.length + " Bluetooth devices.");

        for (const device of devices) {
          console.log("Devices Found : ", device.name);

          if (device.name === printer.name) {
            console.log("Paired Device : ", printer.name);
            device.gatt
              .connect()
              .then((server) =>
                server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
              )
              .then((service) =>
                service.getCharacteristic(
                  "00002af1-0000-1000-8000-00805f9b34fb"
                )
              )
              .then((characteristic) => {
                !!testPrint && testPrint(characteristic);
                setPrinter(characteristic);
              })
              .catch((error) => {
                console.log("deviceConnect Err! " + error);
                !!alert && alert("Koneksi printer gagal");
              });

            break;
          }
        }
      })
      .catch(async (error) => {
        console.log("getDevices Err! " + error);

        await btRequest(printer, setPrinter, testPrint, alert);
      });
  } else {
    await btSetup(setPrinter, testPrint, alert);
  }
};

const btRequest = async (printer, setPrinter, testPrint, alert) => {
  if (!!printer.name) {
    await navigator.bluetooth
      .requestDevice({
        filters: [
          {
            name: [printer.name],
            services: ["000018f0-0000-1000-8000-00805f9b34fb"],
          },
        ],
      })
      .then((device) => {
        device.gatt
          .connect()
          .then((server) =>
            server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
          )
          .then((service) =>
            service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
          )
          .then((characteristic) => {
            !!testPrint && testPrint(characteristic);
            setPrinter(characteristic);
          })
          .catch((error) => {
            console.log("deviceConnect Err! " + error);
            !!alert && alert("Koneksi printer gagal");
          });
      })
      .catch((error) => {
        console.log("getDevices Err! " + error);
      });
  }
};
