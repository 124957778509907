import { giftFormat } from "@src/constants";

export const itemCount = (gift) => {
  if (!!!gift) return 0;

  let count = 0;

  const giftArr = gift.split(",").map((str) => str.replace(/\D+/g, ""));

  giftArr.forEach((str) => {
    count += parseInt(str);
  });

  return count;
};

export const string2values = (string, buttons) => {
  const strArr = string?.split(",") || [];

  let values = {};

  buttons.forEach((button) => {
    const idx = strArr
      .map((str) => str.replace(/\(\d+\)+/g, ""))
      .indexOf(button.label);

    values[button.countId] = parseInt(strArr[idx]?.replace(/\D/g, "") || "0");
  });

  return values;
};

export const values2string = (values, buttons) => {
  let strings = buttons.map((button) => {
    if (!!values[button.countId]) {
      return `${button.label}(${values[button.countId]})`;
    } else {
      return null;
    }
  });

  strings = strings.filter((string) => !!string);

  return strings.join(",");
};

export default itemCount;

export const recordsGiftPrep = (iterasi = []) => {
  return iterasi.map((item, index) => {
    let giftDesc = iterasi[index].ivts_GiftDesc;

    if (index > 0) {
      let prevIndex = index - 1;
      for (var i = prevIndex; i > 0; i--) {
        if (!!iterasi[i].ivts_GiftDesc) {
          break;
        }

        prevIndex = i - 1;
      }

      const currGift = string2values(item.ivts_GiftDesc, giftFormat);
      const prevGift = string2values(
        iterasi[prevIndex].ivts_GiftDesc,
        giftFormat
      );

      let result = {};
      giftFormat.forEach((gift) => {
        result[gift.countId] =
          Number(currGift[gift.countId]) - Number(prevGift[gift.countId]);
      });

      giftDesc = values2string(result, giftFormat);
    }

    return {
      ...item,
      ivts_GiftDesc: giftDesc,
    };
  });
};
