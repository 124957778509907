import React, { useState, useEffect } from "react";

import { styled } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  background: "white",
  marginTop: "0.5rem",

  "&:hover": {
    boxShadow: "0px 0px 1px 3px #d0cec980",

    fieldset: {
      border: "1px solid #b3b3b3 !important",
    },
  },

  "&.Mui-focused fieldset": {
    boxShadow: "0px 0px 1px 3px #d0cec980",
    border: "1px solid #b3b3b3 !important",
  },

  "& .MuiOutlinedInput-input": {
    height: "2rem",
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    padding: "10px 12px",
    textAlign: "center",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&.Mui-disabled": {
      webkitTextFillColor: "black",
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0.5rem",
  "&:active": {
    "& .MuiSvgIcon-root": { fill: "#03a798" },
  },
}));

const NumericStepper = (props) => {
  const { id, label, helper, value, addition, onChange } = props;

  const [inputValue, setInputValue] = useState(0);
  const [inputString, setInputString] = useState("");

  useEffect(() => setInputValue(value), [value]);

  useEffect(
    () => setInputString(!!addition ? inputValue + addition : inputValue),
    [inputValue, addition]
  );

  const handleInc = () => {
    handleChange(inputValue + 1);
  };

  const handleDec = () => {
    inputValue > 0 && handleChange(inputValue - 1);
  };

  const handleChange = (newValue) => {
    onChange({ target: { id: id, value: newValue } });

    setInputValue(newValue);
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        flexDirection: "column",
        width: "100%",
        "@media screen and (min-width: 768px)": {
          flexDirection: "row",
          justifyContent: "space-between",
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          width: "40%",
          padding: "0 0.25rem",

          "@media screen and (max-width: 768px)": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          },
        }}
      >
        <Typography
          align="center"
          variant="h6"
          component="span"
          color="#747474"
          sx={{
            fontSize: "1.15rem",
            "@media screen and (max-width: 768px)": { fontSize: "1rem" },
          }}
        >
          {label || "unlabeled"}
        </Typography>

        {!!helper && (
          <Typography
            align="center"
            variant="body1"
            component="span"
            color="#ff0000"
            sx={{
              fontSize: "0.75rem",
              "@media screen and (max-width: 768px)": { fontSize: "0.75rem" },
            }}
          >
            {helper}
          </Typography>
        )}
      </Stack>

      <StyledInput
        autoComplete="off"
        value={inputString}
        disabled
        sx={{
          width: "100%",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              webkitAppearance: "none",
              margin: 0,
            },
          "@media screen and (min-width: 768px)": { width: "60%" },
        }}
        startAdornment={
          <InputAdornment position="start">
            <StyledIconButton size="large" onClick={handleDec}>
              <RemoveCircleIcon fontSize="2.5rem" />
            </StyledIconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <StyledIconButton size="large" onClick={handleInc}>
              <AddCircleIcon fontSize="2.5rem" />
            </StyledIconButton>
          </InputAdornment>
        }
      />
    </Stack>
  );
};

export default NumericStepper;
