const totwodigit = (number) => {
  return (number = number < 10 ? `0${number}` : number.toString());
};

export const date2time = (timestamp) => {
  const date = new Date(timestamp);

  const hh = date.getHours();
  const mm = date.getMinutes();
  const ss = date.getSeconds();

  return `${totwodigit(hh)}:${totwodigit(mm)}:${totwodigit(ss)}`;
};

export const str2datetime = (str) => {
  const date = new Date(str);

  return date;
};

export const timestamp = () => {
  let date = new Date();
  let dd = date.getDate();
  let mo = date.getMonth() + 1;
  let yy = date.getFullYear();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();

  let timestamp =
    (yy < 10 ? "0" + yy : yy) +
    "-" +
    (mo < 10 ? "0" + mo : mo) +
    "-" +
    (dd < 10 ? "0" + dd : dd) +
    " " +
    (hh < 10 ? "0" + hh : hh) +
    ":" +
    (mm < 10 ? "0" + mm : mm) +
    ":" +
    (ss < 10 ? "0" + ss : ss);

  return timestamp;
};
