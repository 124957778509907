const descendingComparator = (a, b, orderBy) => {
  let value_a = `${a[orderBy] || ""}`;
  let value_b = `${b[orderBy] || ""}`;

  value_a = value_a.toLowerCase();
  value_b = value_b.toLowerCase();

  if (value_b < value_a || !!!value_b) {
    return -1;
  }
  if (value_b > value_a || !!!value_a) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const sortItems = (table, sortType = "asc", propName) => {
  const stabilizedThis = table.map((el, index) => [el, index]);
  const comparator = getComparator(sortType, propName);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const compareObj = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export const compareArr = (a, b) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

export const findUnique = (obj, property) => {
  let result = [];

  result = obj.map((item) => item[property]);

  result = [...new Set(result)];

  result = result.filter((item) => item);

  return result;
};
