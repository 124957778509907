import { useEffect, useRef, useState } from "react";

import FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import DataTableHeader from "./DataTableHeader";
import DataTableMenu from "./DataTableMenu";
import DataTableRow from "./DataTableRow";
import DataTableToolbar from "./DataTableToolbar";

import Loader from "@components/Loader";
import { timestamp } from "@helper/dateTimeHelper";
import { sortItems } from "@helper/itemsHelper";
import useViewport from "@hooks/useViewport";

import { breakPoints } from "@src/constants";

const DataTable = ({
  auth,
  data,
  format,
  recordsFormat,
  sortOrder = "desc",
  sortBy,
  onDataUpdate,
  onDataChange,
  isFetching,
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableSearch, setTableSearch] = useState(null);
  const [tableFilter, setTableFilter] = useState({});
  const [tableSort, setTableSort] = useState({
    sortOrder: sortOrder,
    sortBy: sortBy,
  });
  const [columnHeaders, setColumnHeaders] = useState(format);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [minRowCount, setMinRowCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [maximize, setMaximize] = useState(false);
  const { width, height } = useViewport();
  const tableBodyRef = useRef(null);
  const portalRef = useRef(null);

  const pageData = tableData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    // Calculate row count
    let rowCount = Math.round(height / 73) + 3;
    // let rowCount = Math.round(height / 56) + 3;
    setMinRowCount(rowCount);
    setRowsPerPage(rowCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading && !isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    const processedData = applyFilters(
      data,
      tableSearch,
      tableFilter,
      tableSort
    );

    const isNewData = !(
      JSON.stringify(processedData) === JSON.stringify(tableData)
    );

    //if (isNewData) {
    setTableData(processedData);
    onDataChange(processedData);

    /* Only reset page if page content empty */
    if (pageData.length === 0) setPage(0);
    //}
  }, [data, tableSearch, tableFilter, tableSort]);

  const handleSelected = (items) => {
    const visibleColumns = columnHeaders.map((column) => {
      if (items.includes(column.header)) {
        return { ...column, hide: false };
      } else {
        return { ...column, hide: true };
      }
    });

    setColumnHeaders(visibleColumns);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRefresh = () => {
    setLoading(true);
    onDataUpdate();
  };

  const handleReset = () => {
    setTableFilter({});
    setTableSort({
      sortOrder: sortOrder,
      sortBy: sortBy,
    });
  };

  const handleMaximize = () => {
    setMaximize(!maximize);
  };

  const handleTableSort = (sortBy, sortOrder = null) => {
    setTableSort((prev) => ({
      sortOrder: !!sortOrder
        ? sortOrder
        : prev.sortOrder === "asc"
        ? "desc"
        : "asc",
      sortBy: sortBy,
    }));
  };

  const handleDownload = () => {
    const monitoringData = tableData.map((item) => {
      let rowData = {};

      columnHeaders.forEach((column) => {
        if (!column.hide) {
          rowData[column.header] = item[column.value];

          if (String(rowData[column.header])?.includes("Hadir(")) {
            rowData[column.header] = item[column.value].replaceAll(/\D/g, "");
          }
        }
      });

      return rowData;
    });

    const stamp = timestamp().replaceAll(/\D/g, "");

    const fileName = `dgsign_${stamp}`;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExt = ".xlsx";
    const worksheet = XLSX.utils.json_to_sheet(monitoringData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([buffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExt);
  };

  const applyFilters = (unfilteredData, search, filter, sort) => {
    let result = unfilteredData;

    if (!!search) {
      const keys = format
        .map((item) => {
          return item.searchable && item.value;
        })
        .filter((item) => {
          return item !== false;
        });

      result = result.filter((item) => {
        for (let key of keys) {
          if (!!item[key]) {
            if (item[key].toLowerCase().indexOf(search.toLowerCase()) !== -1)
              return true;
          }
        }
        return false;
      });
    }

    if (Object.keys(filter).length) {
      const filterKeys = Object.keys(filter);
      const isFilterGiftDesc = filterKeys.some(
        (key) => key === "ivts_GiftDesc"
      );

      if (isFilterGiftDesc) {
        result = result.map((item) => {
          if (!!!item.ivts_GiftDesc) return item;

          let filteredGifts = item.ivts_GiftDesc.split(",");

          filteredGifts = filteredGifts.filter((gift) =>
            filter["ivts_GiftDesc"].some((query) => gift.includes(query))
          );

          return {
            ...item,
            ivts_GiftDesc: filteredGifts.join(","),
          };
        });
      }

      result = result.filter((item) => {
        let status = false;

        for (let idx = 0; idx < filterKeys.length; idx++) {
          const filterKey = filterKeys[idx];

          for (let keyIdx = 0; keyIdx < filter[filterKey].length; keyIdx++) {
            const filterTarget = !!item[filterKey]
              ? item[filterKey].toLowerCase().replaceAll(/\(\d+\)/g, "")
              : "";
            const filterQuery = filter[filterKey][keyIdx].toLowerCase();
            const isExactFilter =
              filterKey === "ivts_RsvpRespond" ||
              filterKey === "ivts_Category" ||
              filterKey === "ivts_Seat" ||
              filterKey === "ivts_GuestAttCounter";

            if (
              (isExactFilter && filterTarget === filterQuery) ||
              (!isExactFilter && filterTarget.includes(filterQuery))
            ) {
              status = true;
              break;
            } else if (status === true) {
              status = false;
            }
          }

          if (status === false) {
            break;
          }
        }

        return status;
      });
    }

    result = sortItems(result, sort.sortOrder, sort.sortBy);

    return result;
  };

  return (
    <>
      <Paper
        sx={
          maximize
            ? {
                position: "absolute",
                height: "100vh",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 2,
              }
            : { position: "inherit" }
        }
      >
        <DataTableToolbar
          onSearch={setTableSearch}
          onRefresh={handleRefresh}
          onMaximize={handleMaximize}
          onReset={handleReset}
          onDownload={handleDownload}
          tableFilter={tableFilter}
          tableSort={tableSort}
          sortOrder={sortOrder}
          sortBy={sortBy}
        />
        <Box sx={{ position: "relative" }}>
          <TableContainer
            component={Paper}
            sx={{
              height:
                maximize && width > breakPoints.md
                  ? height - 140
                  : height - 180,
            }}
          >
            <Table
              aria-label="collapsible table"
              key="datatable_table_container"
              sx={{
                borderCollapse: "separate",
              }}
            >
              <DataTableHeader
                headers={columnHeaders}
                data={data}
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
                tableSort={tableSort}
                onTableSort={handleTableSort}
                portalRef={portalRef}
              />

              <TableBody key="datatable_body" ref={tableBodyRef}>
                {pageData.map((data, index) => (
                  <DataTableRow
                    auth={auth}
                    key={"datatable_body_row" + index}
                    data={data}
                    format={columnHeaders}
                    recordsFormat={recordsFormat}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack
            direction={width <= breakPoints.md ? "column" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DataTableMenu
              multiple={true}
              items={format.map((item) => ({
                value: item.header,
                default: !item.hide,
              }))}
              itemsSelected={handleSelected}
              sx={{
                width: "90%",
                maxWidth: width <= breakPoints.md ? "25rem" : "15rem",
              }}
            />

            <TablePagination
              component="div"
              rowsPerPageOptions={[minRowCount, 50, 100]}
              count={tableData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={
                width > breakPoints.md
                  ? "Rows per page"
                  : width > breakPoints.xs
                  ? "Rows"
                  : ""
              }
            />
          </Stack>

          <Box ref={portalRef} />

          {loading ? (
            <Box
              sx={{
                position: "absolute",
                zIndex: "2",
                top: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                background: "#ffffffc0",
                borderRadius: "0.25rem",
              }}
            >
              <Loader
                style={{
                  margin: "auto",
                }}
                size="3rem"
                color="#ffffff"
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DataTable;
