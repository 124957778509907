import { useState } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { recordsGiftPrep } from "@helper/giftHelper";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import PaidIcon from "@mui/icons-material/Paid";
import { Stack } from "@mui/material";
import guestAPI from "@src/services/api/guestAPI";
import { useEffect } from "react";

const DataTableRow = ({ auth, data, format, recordsFormat }) => {
  const [recordData, setRecordData] = useState({});

  useEffect(() => {
    data.ivts_Uuid !== recordData.ivts_Uuid && setRecordData({});
  }, [data]);

  const isRecordOpen = !!recordData?.iterasi_list;

  const handleClick = async () => {
    if (!isRecordOpen) {
      const loadData = await guestAPI.info(
        auth.clientId.toString(),
        data.ivts_Uuid
      );

      const iterasi = recordsGiftPrep(loadData?.iterasi_list || []);

      setRecordData({ ...loadData, iterasi_list: [...iterasi] });
    } else {
      setRecordData({});
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            {!!isRecordOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>

        {format.map((column, index) => (
          <TableCell
            key={"datatablerow_column_" + index}
            align={column.align}
            sx={{
              display: column.hide ? "none" : "",
              borderLeft: "1px solid #ececec",
            }}
          >
            {column.type === "checkbox" ? (
              <PresentTag
                state={data[column.value].includes(column.options[0])}
              />
            ) : column.type === "icon" ? (
              string2icons(data[column.value])
            ) : (
              data[column.value]
            )}
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "none",
            width: "80rem",
          }}
          colSpan={6}
        >
          <Collapse
            in={isRecordOpen}
            timeout="auto"
            unmountOnExit
            sx={{ width: "max-content" }}
          >
            <Box sx={{ margin: 1 }}>
              <Table
                key="datatable_record"
                size="small"
                aria-label="guestdata"
                sx={{
                  background: "#f7f7f7",
                }}
              >
                <TableHead>
                  <TableRow>
                    {recordsFormat.column.map((row, index) => (
                      <TableCell key={"record_header" + index} align="center">
                        {row.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {isRecordOpen &&
                    recordData[recordsFormat.id].map((record, index) => (
                      <TableRow key={"record_" + index}>
                        {recordsFormat.column.map((row, recordsIndex) => (
                          <TableCell
                            key={"record_row" + recordsIndex}
                            align="center"
                          >
                            {row.type === "index"
                              ? index + 1
                              : row.type === "icon"
                              ? string2icons(record[row.value])
                              : record[row.value]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DataTableRow;

const icons = [
  { label: "Transfer", icon: <PaidIcon /> },
  { label: "Tea Pai", icon: <EmojiFoodBeverageIcon /> },
  { label: "Kado", icon: <CardGiftcardIcon /> },
  { label: "Bunga", icon: <LocalFloristIcon /> },
];

const string2icons = (string = "", key) => {
  if (!!!string) return <></>;

  const strArr = string.split(",") || "";

  const iconList = icons.map((icon, index) => {
    const idx = strArr
      .map((str) => str.replace(/\(\d+\)+/g, ""))
      .indexOf(icon.label);

    const value = parseInt(strArr[idx]?.replace(/\D/g, "") || "0");

    if (idx === -1 || value === 0)
      return <Box key={"cell_icon_" + index} sx={{ display: "none" }} />;

    return (
      <Box
        key={"cell_icon_" + index}
        sx={{
          display: "inline-flex",
          width: "max-content",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "1rem",
          color: "#525252",

          "& .MuiSvgIcon-root": { fontSize: "inherit", marginRight: "0.25rem" },
        }}
      >
        {icon.icon} {value}
      </Box>
    );
  });

  return (
    <Stack key={key} direction="row" gap={2}>
      {iconList}
    </Stack>
  );
};

const PresentTag = ({ state }) => {
  return (
    <>
      {state ? (
        <CheckBoxOutlinedIcon sx={{ fontSize: "1.5rem", color: "#509b50" }} />
      ) : (
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ fontSize: "1.5rem", color: "#df6262" }}
        />
      )}
    </>
  );
};
