import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Button from "@components/Button";
import { date2time } from "@helper/dateTimeHelper";
import useViewport from "@hooks/useViewport";
import { breakPoints } from "@src/constants";

const CancellableBox = (props) => {
  const { data, setConfirmStep, onClose } = props;

  const { width } = useViewport();

  return (
    <Stack alignItems="center" gap={1}>
      <ErrorOutlineIcon sx={{ color: "#df6262", fontSize: "4rem" }} />

      <Stack gap={2} sx={{ marginBottom: "1rem" }}>
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontSize: "1.75rem",
            color: "#0d9488",
            textAlign: "center",
          }}
        >
          {data?.ivts_Name}
        </Typography>

        <Typography
          component="span"
          variant="h5"
          sx={{
            fontSize: "0.75rem",
            color: "#f9e9c1",
            background: "#0d9488",
            textAlign: "center",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.5rem",
          }}
        >
          Sudah melakukan Registrasi
        </Typography>
      </Stack>

      <Typography
        component="span"
        variant="body1"
        sx={{
          color: "#9b9178",
          textAlign: "center",
          fontSize: { xs: "0.8rem", md: "1rem" },
        }}
      >
        {"Registrasi terakhir Pukul : " + date2time(data?.ivts_GuestAttTime)}
        <br />
        {"Di Counter : " + data?.ivts_GuestAttCounter}
      </Typography>

      <Stack sx={{ marginTop: "3rem", width: "100%" }}>
        {" "}
        <Typography
          component="h5"
          variant="h5"
          sx={{
            marginTop: "-0.5rem",
            marginBottom: "0.5rem",
            fontSize: "1.2rem",
            color: "#df6262",
            textAlign: "center",
          }}
        >
          {`Apakah anda ingin melanjutkan registrasi yang ke-${
            data?.iterasi_list?.length + 1
          }?`}
        </Typography>
        <Stack
          gap={width < breakPoints.sm ? 0 : 2}
          direction={width < breakPoints.sm ? "column" : "row"}
        >
          <Button onClick={() => setConfirmStep("conf")}>Ya, Lanjutkan</Button>

          <Button variant="outlined" onClick={onClose}>
            Batal
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CancellableBox;
