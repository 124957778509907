import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const BootstrapInput = styled(TextField)(({ theme }) => ({
  background: "white",
  marginTop: "0.5rem",
  borderRadius: "0.25rem",

  "&:hover": {
    boxShadow: "0px 0px 1px 3px #d0cec980",

    fieldset: {
      border: "1px solid #b3b3b3 !important",
    },
  },

  "& .Mui-focused fieldset": {
    boxShadow: "0px 0px 1px 3px #d0cec980",
    border: "1px solid #b3b3b3 !important",
  },

  "& .MuiOutlined-input": {
    height: "2rem",
    position: "relative",
    fontSize: 16,
    padding: "10px 12px",
    textAlign: "left",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const Selection = (props) => {
  const { name, label, options, initialValue, onSelected } = props;

  const [value, setValue] = useState(initialValue || options[0]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Stack sx={{ display: "flex" }}>
      <Typography
        variant="subtitle1"
        component="h6"
        sx={{ fontWeight: "bold", color: "#adaca8" }}
      >
        {label}
      </Typography>
      <Autocomplete
        autoComplete={false}
        blurOnSelect={true}
        size="small"
        disablePortal
        value={value}
        onChange={(event, newValue) => {
          onSelected(name, newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id={"searchfilter_" + name}
        options={options}
        renderInput={(params) => (
          <BootstrapInput variant="outlined" {...params} />
        )}
      />
    </Stack>
  );
};

export default Selection;
