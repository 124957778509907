/* eslint-disable no-throw-literal */
import apiRequest from "../apiRequest";

const ENDPOINT = "login";

const auth = async (username, password) => {
  let response;
  let reply;

  if (!!!username && !!!password) {
    /* Input Incomplete */
    throw "INPUT";
  }

  const param = {
    username,
    password,
  };

  await apiRequest("post", ENDPOINT, param).then((res) => {
    response = res;
  });

  const message = response.message;

  if (message.includes("koneksi")) {
    throw "CONN";
  } else if (message.includes("terdaftar")) {
    throw "USER";
  } else if (message.includes("salah")) {
    throw "PASS";
  } else if (message.includes("Some fields")) {
    throw "INPUT";
  } else {
    /* if (message.includes("successfull") === true) */
    reply = { client_Id: response.data.clients.client_Id, error: "NONE" };
  }

  return reply;
};

export default auth;
