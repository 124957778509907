import React from "react";
import { styled } from "@mui/material/styles";

export const Spin = styled("div")(({ color, size }) => ({
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },

  color: color || "inherit",
  border: "0.2em solid rgba(0, 0, 0, 0.1)",
  borderTop: "0.2em solid",
  borderRadius: "50%",
  width: size || "1rem",
  height: size || "1rem",
  margin: "0 0.5rem",
  animation: "spin 0.6s linear infinite",
}));

const Loader = ({ size, color }) => {
  return <Spin color={color} size={size} />;
};

export default Loader;
