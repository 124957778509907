import useViewport from "@hooks/useViewport";
import Alert from "@mui/material/Alert";
import { default as MuiSnackbar } from "@mui/material/Snackbar";
import { breakPoints } from "@src/constants";

const Snackbar = ({ open, variant, message, onClose, duration = 3000 }) => {
  const { width } = useViewport();

  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={duration}
      onClose={onClose}
      sx={{ marginTop: width <= breakPoints.md ? "1rem" : "6rem" }}
    >
      <Alert elevation={3} onClose={onClose} severity={variant}>
        {message || "Snackbar Message !"}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
